import { useDispatch, useSelector } from 'react-redux';
import { getEntity } from '../selectors';
import useFilters from './useFilters';
import { useMemo } from 'react';
import { updatePersistedFilters } from '../../../redux/config/actions';

const useStaticFilterMultiselect = (filterKey, filterValues) => {
  const dispatch = useDispatch();
  const entity = useSelector(getEntity);

  const { persistedFilters, handleFilterReset, formatStringFilter } =
    useFilters({ entity, skip: true });

  const selected = persistedFilters?.parameters?.[filterKey] ?? '';

  const isResetDisabled = !selected;

  const countSelected = useMemo(() => {
    if (!selected?.length) return 0;
    return selected.split(',').length;
  }, [selected]);

  const updateFilterState = (newString) => {
    const mutatedParameters = { ...persistedFilters.parameters };

    if (newString?.length) {
      mutatedParameters[filterKey] = newString;
    } else {
      if (filterKey in mutatedParameters) delete mutatedParameters[filterKey];
    }

    dispatch(updatePersistedFilters(mutatedParameters, entity));
  };

  const handleSelectAll = () => {
    const allValues = Object.values(filterValues);
    const isAllSelected = allValues.every((item) => selected.includes(item));
    const newValue = isAllSelected ? null : allValues.toString();

    updateFilterState(newValue);
  };

  const handleSingleValueToggle = (value) => {
    const newString = formatStringFilter(selected, value);
    updateFilterState(newString);
  };

  const handleFilterChange = (value) => {
    if (value === 'all') {
      handleSelectAll();
      return;
    }

    handleSingleValueToggle(value);
  };

  const filterValuesArray = useMemo(() => {
    return Object.values(filterValues);
  }, [filterValues]);

  const getIsSelected = (value) => {
    const selectedValues = selected.split(',');
    const isAllSelected = filterValuesArray.every((item) =>
      selectedValues.includes(item)
    );
    const isItemSelected = selectedValues.includes(value);
    const isSelected = isAllSelected || isItemSelected;

    return isSelected;
  };

  return {
    selected,
    isResetDisabled,
    countSelected,
    handleFilterChange,
    filterValuesArray,
    handleFilterReset,
    getIsSelected,
  };
};

export default useStaticFilterMultiselect;
