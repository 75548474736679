import { lazy } from 'react';

export const QuickFilters = lazy(() => import('../components/FilterQuick'));
export const Type = lazy(() => import('../components/FilterType'));
export const Status = lazy(() => import('../components/FilterStatus'));
export const Workspaces = lazy(() => import('../components/FilterWorkspaces'));
export const Users = lazy(() => import('../components/FilterUsers'));
export const Collaborators = lazy(() =>
  import('../components/FilterCollaborators')
);
export const DateRange = lazy(() => import('../components/FilterDateRange'));
export const Priority = lazy(() => import('../components/FilterPriority'));
export const Schedules = lazy(() => import('../components/FilterSchedules'));
export const Category = lazy(() => import('../components/FilterCategory'));
export const Assets = lazy(() => import('../components/FilterAssets'));
export const Position = lazy(() => import('../components/FilterPosition'));
export const Externals = lazy(() => import('../components/FilterExternals'));
export const FilterSchedulesStatus = lazy(() =>
  import('../components/FilterSchedulesStatus')
);
export const Equipment = lazy(() => import('../components/FilterEquipment'));
export const FilterCollaboratorsStatus = lazy(() =>
  import('../components/FilterCollaboratorsStatus')
);
export const FilterAccessType = lazy(() =>
  import('../components/FilterAccessType')
);
export const FilterCollaboratorsType = lazy(() =>
  import('../components/FilterCollaboratorsType')
);

export const FilterAssetsStatus = lazy(() =>
  import('../components/FilterAssetsStatus')
);
export const FilterAssetsModels = lazy(() =>
  import('../components/FilterAssetsModels')
);
export const FiltersAssetsManufacturers = lazy(() =>
  import('../components/FilterAssetsManufacturers')
);
export const FilterAssetsGroups = lazy(() =>
  import('../components/FilterAssetsGroups')
);
export const FilterAssetsSystems = lazy(() =>
  import('../components/FilterAssetsSystems')
);
export const FilterManagers = lazy(() =>
  import('../components/FilterManagers')
);
export const FilterAssetsCommonStatus = lazy(() =>
  import('../components/FilterAssetsCommonStatus')
);
export const FilterLegalRequrements = lazy(() =>
  import('../components/FilterLegalRequirements')
);

export const FilterEquipmentStatus = lazy(() =>
  import('../components/FilterEquipmentStatus')
);

export const FilterEquipmentGroups = lazy(() =>
  import('../components/FilterEquipmentGroups')
);

export const FilterEquipmentTypes = lazy(() =>
  import('../components/FilterEquipmentTypes')
);

export const FilterHolder = lazy(() => import('../components/FilterHolder'));
export const FilterReservedFor = lazy(() =>
  import('../components/FilterReservedFor')
);
