import { FormattedMessage } from 'react-intl';
import { internalRoutePaths } from '../../../../../config/config';
import showDirections from '../../../../../utils/showDirections';
import { getIssue } from '../../../users/apiService';
import { matchPath } from 'react-router';
import dayjs from 'dayjs';

export const getTabsByPermissions = (settings) => {
  const tabs = [
    { text: <FormattedMessage id="all" /> },
    { icon: 'task', value: 'issue' },
  ];

  if (settings?.include_schedules) {
    tabs.push({
      icon: 'scheduling',
      value: 'schedule',
    });
  }
  if (settings?.include_equipment) {
    tabs.push({
      icon: 'equipment',
      value: 'equipment',
    });
  }
  if (settings?.include_assets) {
    tabs.push({
      icon: 'assets',
      value: 'asset',
    });
  }
  return tabs;
};

export const redirectToWorkspacesList = (history) => {
  history.push({
    pathname: internalRoutePaths.WORKSPACES,
  });
};

const redirectToEquipmentItem = (history, id, state = undefined) => {
  history.push({
    pathname: internalRoutePaths.EQUIPMENT_ID.replace(':id', id),
    state,
  });
};

const redirectToWorksaceLeaseInfo = (history, id, sectionId) => {
  history.push(
    {
      pathname: internalRoutePaths.WORKSPACE_LEASE_INFO.replace(':id', id),
    },
    { section: sectionId }
  );
};

const redirectToSchedule = (history, id) => {
  history.push({
    pathname: internalRoutePaths.SCHEDULING_ID.replace(':id', id),
  });
};

const redirectToIssue = (history, issue, additionalParams = {}) => {
  const route = internalRoutePaths.ISSUES_ID.replace(':id', issue.id);

  const isIssuePageOpen = matchPath(
    history.location.pathname,
    internalRoutePaths.ISSUES_ID
  );

  history.push({
    pathname: route,
    state: {
      ...additionalParams,
    },
  });

  if (isIssuePageOpen) history.go(0);
};

const redirectToIssuesList = ({ history, state }) => {
  history.push({
    pathname: internalRoutePaths.ISSUES,
    state,
  });
};

const redirectToAssetItem = ({ history, id, state }) => {
  history.push({
    pathname: internalRoutePaths.ASSETS_ID.replace(':id', id),
    state,
  });
};

const redirectToWorkspaceItem = ({ history, id, state }) => {
  history.push({
    pathname: internalRoutePaths.WORKSPACES_ID.replace(':id', id),
    state,
  });
};

const redirectToCalendar = ({ history, data }) => {
  const { time } = data;
  const date = dayjs(time * 1000).format('YYYY-MM-DD');
  history.push({
    pathname: internalRoutePaths.CALENDAR,
    search: `?end_date=${date}&start_date=${date}&page=1&paginate_by=25`,
  });
};

const redirectBasedOnModelPage = ({ history, data }) => {
  const { model_page: modelPage, model_type: id } = data;

  const urls = {
    workspace: internalRoutePaths.WORKSPACES_ID.replace(':id', id),
    schedule: internalRoutePaths.SCHEDULING_ID.replace(':id', id),
  };

  history.push(urls[modelPage]);
};

export const redirectUser = async (
  history,
  data,
  closeDropdown,
  handleGoToEquipmentListing,
  toggleIssueMissingModal,
  handleSetForceSurveyShowModal
) => {
  switch (data.type) {
    case 'qr_scanlog_unknown_location':
      closeDropdown();
      return redirectBasedOnModelPage({ history, data });
    case 'users_added_to_workspaces':
      return redirectToWorkspacesList(history);

    case 'qr_scan_wrong_location':
    case 'qr_scanlog_wrong_location':
      const { origin_location, scan_location } = data.location;
      return showDirections(origin_location, scan_location);

    case 'workspace_session_expired':
    case 'users_added_to_one_workspace':
      closeDropdown();
      return redirectToWorkspaceItem({ history, id: data.workspace_id });

    case 'schedule_no_login':
    case 'schedule_early_login':
    case 'schedule_early_logout':
    case 'schedule_not_logged_out_yet':
      closeDropdown();
      return redirectToCalendar({ history, data });

    //equipment
    case 'equipment_user_took':
    case 'equipment_accepted_return_date':
    case 'equipment_issued':
    case 'equipment_returned':
    case 'equipment_updated_return_date':
    case 'equipment_decline_return_date':
    case 'equipment_return_date_approaching':
    case 'equipment_user_took_over':
    case 'equipment_reservation_buffer_denied':
    case 'equipment_reservation_buffer_accepted':
    case 'equipment_reported_found':
    case 'equipment_reserved_for_user':
    case 'equipment_item_returned_by_issuer':
    case 'equipment_issued_to_holder':
    case 'equipment_reserved_for_manager':
    case 'equipment_reservation_update':
    case 'equipment_reservation_update_watcher':
    case 'equipment_reservation_approaching':
    case 'reservation_expired_without_handout':
      closeDropdown();
      return redirectToEquipmentItem(history, data.equipment_id);

    case 'equipment_request_approval_reservation':
      closeDropdown();
      return redirectToEquipmentItem(history, data.equipment_id, {
        notifications: {
          equipmentRequestApprovalReservation: data,
        },
      });

    case 'equipment_handout_extend_requested':
      return redirectToEquipmentItem(history, data.equipment_id, {
        notifications: {
          equipmentHandoutExtendRequested: true,
        },
      });

    case 'equipment_group_user_took':
    case 'equipment_group_issued':
    case 'group_equipment_handout_extend_requested':
    case 'equipment_group_updated_return_date':
    case 'equipment_group_returned_by_issuer':
    case 'equipment_group_reserved_for_user':
    case 'equipment_group_reserved_for_manager':
    case 'equipment_group_returned':
    case 'equipment_group_accepted_return_date':
    case 'equipment_group_decline_return_date':
    case 'equipment_group_reservation_updated':
    case 'equipment_group_reservation_updated_watcher':
      closeDropdown();
      return handleGoToEquipmentListing(data, 'group_ids');
    case 'open_survey':
      return handleSetForceSurveyShowModal(true);

    //assets
    case 'asset_status_changed':
      closeDropdown();
      return redirectToAssetItem({ history, id: data.asset_id });
    case 'asset_upcoming_warranty':
      closeDropdown();
      return redirectToAssetItem({
        history,
        id: data.asset_id,
        state: { assetUpcomingWarranty: true },
      });
    case 'issues_on_asset':
    case 'asset_task_freq_exceeded':
      closeDropdown();
      return redirectToIssuesList({
        history,
        state: {
          asset: {
            id: data.asset_id,
            value: data.asset_name || data.asset_title, //due to BE not baing consistent
            name: data.asset_name || data.asset_title, //due to BE not baing consistent
          },
        },
      });
    default:
  }

  if (data.workspace_id) {
    return redirectToWorksaceLeaseInfo(
      history,
      data.workspace_id,
      data.section_id
    );
  }

  if (data.schedule_id) {
    closeDropdown();
    return redirectToSchedule(history, data.schedule_id);
  }

  const response = await getIssue(data.issue_id);

  if (!response.data) {
    return toggleIssueMissingModal();
  } else {
    closeDropdown();
    redirectToIssue(history, response.data, {
      fromNotification: true,
      ...(data?.type === 'issue_declined_by_external'
        ? { declinedByExternal: true }
        : {}),
    });
  }
};

export const normalizeNotificationData = (data) => {
  const object = {};

  data.forEach((item) => (object[item.id] = Boolean(item.read_at)));

  return object;
};
