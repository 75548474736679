import { isEqual } from 'lodash-es';
import { ROUTE_MAPPED_PERSISTED_FILTER_KEY } from './constants';

export const formatWithNoEmptyValues = (data) => {
  const clonedData = { ...data };

  for (const [key, value] of Object.entries(clonedData)) {
    if (value === null || value === undefined || value === '') {
      delete clonedData[key];
    }
  }

  return clonedData;
};

export const getUrlParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
};

export const mergeCustomUrlParamsIntoInitFilters = (
  initFiltersState,
  urlConfig
) => {
  const { pathname, urlParams } = urlConfig;
  if (!pathname || !urlParams) return initFiltersState;

  const persistedFilterKey = ROUTE_MAPPED_PERSISTED_FILTER_KEY[pathname];
  const persistedFilter = initFiltersState[persistedFilterKey]?.parameters;

  if (isEqual(persistedFilter, urlParams)) return initFiltersState;

  const mutatedInitFiltersState = { ...initFiltersState };
  const mutatedPersistedFilter = { ...persistedFilter };

  for (const key in urlParams) {
    if (!mutatedPersistedFilter[key]) {
      const values = urlParams[key].split(',') || [];
      mutatedPersistedFilter[`${key}_params`] = values.map((value) => ({
        id: value,
      }));
    }
  }

  mutatedInitFiltersState[persistedFilterKey] = {
    ...mutatedInitFiltersState[persistedFilterKey],
    parameters: mutatedPersistedFilter,
  };

  return mutatedInitFiltersState;
};

export const mergeFilterSources = ({
  urlParams,
  backendFilters,
  defaultFilters,
}) => {
  //NOTE: due to BE inconsistency, we add fallback as BE can return empty array and completelly miss type
  if (
    Array.isArray(backendFilters) ||
    !backendFilters ||
    typeof backendFilters !== 'object'
  ) {
    return urlParams;
  }

  const result = { ...urlParams };

  Object.entries(backendFilters).forEach(([filterKey, filterValue]) => {
    if (Array.isArray(filterValue?.parameters)) {
      return;
    }

    const defaultFilter = defaultFilters[filterKey];
    const urlParamsFilter = urlParams[filterKey];

    result[filterKey] = { ...filterValue };

    if (filterValue.parameters && typeof filterValue.parameters === 'object') {
      const mergedParams = {};
      Object.entries(filterValue.parameters).forEach(
        ([paramKey, paramValue]) => {
          // If URL params have specific value and it differs from default,
          // keep the URL param value, otherwise use backend value
          const useUrlValue =
            urlParamsFilter?.parameters?.[paramKey] !== undefined &&
            !isEqual(
              defaultFilter?.parameters?.[paramKey],
              urlParamsFilter?.parameters?.[paramKey]
            );

          mergedParams[paramKey] = useUrlValue
            ? urlParamsFilter.parameters[paramKey]
            : paramValue;
        }
      );

      // Add any URL parameters not in backend filters
      if (urlParamsFilter?.parameters) {
        Object.entries(urlParamsFilter.parameters).forEach(
          ([paramKey, paramValue]) => {
            if (mergedParams[paramKey] === undefined) {
              mergedParams[paramKey] = paramValue;
            }
          }
        );
      }

      result[filterKey].parameters = mergedParams;
    }
  });

  return result;
};
