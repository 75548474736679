import dayjs from 'dayjs';
import { FILTER_END_DATE_KEY, FILTER_START_DATE_KEY } from './constants';

export const formatToUnix = (startDate, endDate) => {
  const startDateUnix = dayjs(startDate).startOf('day').unix();
  const endDateUnix = dayjs(endDate).endOf('day').unix();

  return {
    startDateUnix,
    endDateUnix,
  };
};

export const getIsSelected = (persistedFilters, startDate, endDate) => {
  const { startDateUnix, endDateUnix } = formatToUnix(startDate, endDate);

  return (
    startDateUnix.toString() ===
      persistedFilters.parameters[FILTER_START_DATE_KEY] &&
    endDateUnix.toString() === persistedFilters.parameters[FILTER_END_DATE_KEY]
  );
};
