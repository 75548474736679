import { useDispatch } from 'react-redux';
import { updatePersistedFilters } from '../../../../../../redux/config/actions';
import { INIT_QUERY as INIT_QUERY_PARAMS_EQUIPMENT } from '../../../../Equipment/constants';
import { useHistory } from 'react-router-dom';
import { internalRoutePaths } from '../../../../../../config/config';

const useGoToEquipment = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleGoToEquipmentListing = (data, key) => {
    if (!data) return;

    //BE is not consistent with data hence check for both name and title!
    const {
      equipment_group_id: id,
      equipment_group_name: name,
      equipment_group_title: title,
      type,
    } = data;

    dispatch(
      updatePersistedFilters(
        {
          ...INIT_QUERY_PARAMS_EQUIPMENT,
          [`${key}_params`]: [
            {
              id,
              name: name || title,
            },
          ],
        },
        'equipment'
      )
    );

    const config = {
      pathname: internalRoutePaths.EQUIPMENT,
    };

    if (type && type === 'group_equipment_handout_extend_requested') {
      config['state'] = {
        notifications: {
          groupEquipmentHandoutExtendRequested: data,
        },
      };
    }

    if (history.location.pathname !== internalRoutePaths.EQUIPMENT) {
      history.push(config);
    }
  };

  return {
    handleGoToEquipmentListing,
  };
};

export default useGoToEquipment;
