import { DENSITY } from '../../../config/config';
import { newRed, orange, textGreen } from '../../../config/colors';

export const PERSISTED_FILTERS_KEY = 'asset';

export const SORT_OPTIONS = [
  {
    value: 'created',
    label: 'creation',
  },
  {
    value: 'updated',
    label: 'updated',
  },
  {
    value: 'name',
    label: 'name',
  },
];

export const SORT_OPTIONS_ASSETS_LIST = [
  ...SORT_OPTIONS,
  {
    value: 'asset_issues',
    label: 'open_issues',
  },
  {
    value: 'asset_tasks',
    label: 'open_tasks',
  },
];

export const STATUS_OPTIONS = {
  active: {
    id: 1,
    labelKey: 'active',
    value: 'ACTIVE',
    color: textGreen,
    shortLabelKey: null,
  },
  activeInMaintenance: {
    id: 2,
    labelKey: 'active_in_maintenance',
    value: 'ACTIVE IN MAINTENANCE',
    color: orange,
    shortLabelKey: 'active',
  },
  notWorkingInMaintenance: {
    id: 3,
    labelKey: 'not_working_in_maintenance',
    value: 'NOT WORKING IN MAINTENANCE',
    color: newRed,
    shortLabelKey: 'in_maintenance',
  },
  inactive: {
    id: 4,
    labelKey: 'inactive',
    value: 'INACTIVE',
    color: null,
    shortLabelKey: null,
  },
  archived: {
    id: 5,
    labelKey: 'archived',
    value: 'ARCHIVED',
    color: null,
    shortLabelKey: null,
  },
};

export const STATUSES = [
  { value: 'all', labelKey: 'status_all' },
  ...Object.values(STATUS_OPTIONS),
];

export const EXPORT_TYPES = ['xlsx', 'json', 'csv', 'xml'];

export const INIT_QUERY_PARAMS = {
  page: '1',
  paginate_by: '20',
  sort_by: 'name',
  sort_direction: 'asc',
  density: DENSITY.comfortable,
};

export const MODAL_OVERLAY_STYLE_OVERRIDE = {
  overflow: 'auto',
};

export const MODAL_CONTENT_STYLE_OVERRIDE = {
  top: '70px',
  transform: 'translate(-50%, 0)',
};

export const FEATURE_KEY = 'assets';

export const SUPPORTED_PARAMS = {
  search: {
    defaultValue: '',
    allowedValues: null,
  },
  density: {
    defaultValue: 'comfortable',
    allowedValues: ['comfortable', 'compact'],
  },
  page: {
    defaultValue: '1',
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  sort_by: {
    defaultValue: 'updated',
    allowedValues: [
      'created',
      'updated',
      'name',
      'asset_issues',
      'asset_tasks',
    ],
  },
  sort_direction: {
    defaultValue: 'asc',
    allowedValues: ['asc', 'desc'],
  },
  status: {
    defaultValue: null,
    allowedValues: null,
  },
  asset_group_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  system_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  workspaces: {
    defaultValue: null,
    allowedValues: null,
  },
  manager_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  manufacturer_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  model_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  location_required: {
    defaultValue: [],
    allowedValues: ['true', 'false'],
  },
  auth_maintenance_required: {
    defaultValue: [],
    allowedValues: ['true', 'false'],
  },
  auth_inspection_required: {
    defaultValue: [],
    allowedValues: ['true', 'false'],
  },
};
