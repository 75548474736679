import * as Components from './components';

export const FILTER_COMPONENTS = {
  quick: Components.QuickFilters,
  type: Components.Type,
  status: Components.Status,
  workspaces: Components.Workspaces,
  users: Components.Users,
  collaborators: Components.Collaborators,
  date: Components.DateRange,
  priority: Components.Priority,
  schedules: Components.Schedules,
  category: Components.Category,
  assets: Components.Assets,
  position: Components.Position,
  externals: Components.Externals,
  statusSchedule: Components.FilterSchedulesStatus,
  statusCollaborators: Components.FilterCollaboratorsStatus,
  statusAssets: Components.FilterAssetsStatus,
  equipment: Components.Equipment,
  equipmentGroups: Components.FilterEquipmentGroups,
  equipmentTypes: Components.FilterEquipmentTypes,
  accessType: Components.FilterAccessType,
  typeCollaborators: Components.FilterCollaboratorsType,
  assetsModels: Components.FilterAssetsModels,
  assetsManufacturers: Components.FiltersAssetsManufacturers,
  assetsGroups: Components.FilterAssetsGroups,
  assetsSystems: Components.FilterAssetsSystems,
  assetsCommonStatus: Components.FilterAssetsCommonStatus,
  managers: Components.FilterManagers,
  legalRequrements: Components.FilterLegalRequrements,
  statusEquipment: Components.FilterEquipmentStatus,
  holder: Components.FilterHolder,
  reservedFor: Components.FilterReservedFor,
};
