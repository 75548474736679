import { DENSITY } from '../../../config/config';

export const PERSISTED_FILTERS_KEY = 'equipment';
const INFINITE_SCROLL_INITIAL_STATE = {
  query: {
    page: '1',
    paginate_by: '10',
  },
  itemsList: [],
  hasMore: true,
  lastPage: null,
};

const INIT_QUERY = {
  page: '1',
  paginate_by: '20',
  sort_by: 'action_required',
  sort_direction: 'asc',
  density: DENSITY.comfortable,
};

const EQUIPMENT_INFINITE_SCROLL_INITIAL_STATE = {
  query: {
    page: '1',
    paginate_by: '10',
    status: 'active',
    sort_by: 'name',
    sort_direction: 'asc',
  },
  itemsList: [],
  hasMore: true,
  lastPage: null,
};

const RTK_HOOK_CONFIG = {
  refetchOnMountOrArgChange: true,
  refetchOnFocus: false,
};

const SUPPORTED_PARAMS_ADVANCED_FILTER = {
  holder_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  reserved_for_user_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  manager_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  group_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  workspaces: {
    defaultValue: null,
    allowedValues: null,
  },
  type_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  include_sub_ws: {
    defaultValue: [],
    allowedValues: ['true', 'false'],
  },
  density: {
    defaultValue: 'comfortable',
    allowedValues: ['comfortable', 'compact'],
  },
  isAdvancedFilterOpen: {
    defaultValue: '0',
    allowedValues: ['0', '1'],
  },
};

const MAIN_SUPPORTED_PARAMS = {
  page: {
    defaultValue: 1,
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  status: {
    defaultValue: null,
    allowedValues: null,
  },
  sort_by: {
    defaultValue: 'name',
    allowedValues: [
      'created',
      'updated',
      'name',
      'overdue',
      'action_required',
      'reserved',
    ],
  },
  sort_direction: {
    defaultValue: 'asc',
    allowedValues: ['asc', 'desc'],
  },
  search: {
    defaultValue: '',
    allowedValues: null,
  },
  ids: {
    defaultValue: null,
    allowedValues: null,
  },
};

const SUPPORTED_PARAMS = {
  ...MAIN_SUPPORTED_PARAMS,
  ...SUPPORTED_PARAMS_ADVANCED_FILTER,
};

const STATUSES = [
  { value: 'all', label: 'status_all' },
  { value: 'active', label: 'active' },
  { value: 'archived', label: 'archived' },
];

const STATUSES_EQUIPMENT_LISTING = [
  { value: 'all', label: 'status_all' },
  { value: 'ISSUED', label: 'issued' },
  { value: 'STORED', label: 'stored' },
  { value: 'active', label: 'active' },
  { value: 'archived', label: 'archived' },
];

export const STATUSES_ADDITIONAL = [
  { value: 'in_transition', label: 'transition' },
];

const SORT_OPTIONS = [
  {
    value: 'created',
    label: 'creation',
  },
  {
    value: 'updated',
    label: 'updated',
  },
  {
    value: 'name',
    label: 'name',
  },
];

const SORT_OPTIONS_EQUIPMENT = [
  {
    value: 'overdue',
    label: 'overdue',
  },
  {
    value: 'reserved',
    label: 'reserved',
  },
  {
    value: 'action_required',
    label: 'action_required',
  },
  ...SORT_OPTIONS,
];

const STATUS_OPTIONS = {
  issued: {
    id: 1,
    labelKey: 'issued',
    value: 'ISSUED',
  },
  stored: {
    id: 2,
    labelKey: 'stored',
    value: 'STORED',
  },
};

const STATUS_OPTIONS_ADDITIONAL = {
  archived: {
    id: 3,
    labelKey: 'archived',
    value: 'ARCHIVED',
  },
  in_transition: {
    id: 4,
    labelKey: 'transition',
    value: 'in_transition',
  },
};

const MODAL_OVERLAY_STYLE_OVERRIDE = {
  overflow: 'auto',
};

const MODAL_CONTENT_STYLE_OVERRIDE = {
  top: '70px',
  transform: 'translate(-50%, 0)',
};

const EXPORT_TYPES = ['xlsx', 'json', 'csv', 'xml'];

const EMAIL_STATUS_MESSAGES = {
  pending: 'email_pending',
  delivered: 'email_delivered',
  temp_fail: 'email_not_delivered',
  permanent_fail: 'email_not_delivered',
};

export {
  INFINITE_SCROLL_INITIAL_STATE,
  EQUIPMENT_INFINITE_SCROLL_INITIAL_STATE,
  RTK_HOOK_CONFIG,
  INIT_QUERY,
  SUPPORTED_PARAMS,
  MAIN_SUPPORTED_PARAMS,
  STATUSES,
  SORT_OPTIONS,
  SUPPORTED_PARAMS_ADVANCED_FILTER,
  STATUS_OPTIONS,
  STATUS_OPTIONS_ADDITIONAL,
  STATUSES_EQUIPMENT_LISTING,
  MODAL_OVERLAY_STYLE_OVERRIDE,
  MODAL_CONTENT_STYLE_OVERRIDE,
  SORT_OPTIONS_EQUIPMENT,
  EXPORT_TYPES,
  EMAIL_STATUS_MESSAGES,
};

export const CUSTOMER_SUPPORT_CONFIG = {
  entity: 'equipment',
  videoId: '2VsCk1S4Pg0',
};
