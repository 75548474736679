import React, { useEffect } from 'react';
import fetchIntercept from 'fetch-intercept';
import { connect, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { ContentArea } from './content-area/content-area.component';
import Header from './header/header.component';

import './main.view.scss';
import { logout } from '../../../redux/auth/action-creators';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import { notificationPink, filtersBlue } from '../../../config/colors';
import {
  getEntity,
  getEntityLoadingStatus,
} from '../../../redux/entities/selectors';
import { entities } from '../../../config/entitiesConfig';
import Sidebar from './SidebarNew/Sidebar';
import useCalendarAppContainer from './hooks/useCalendarAppContainer';
import { getIsLoadingSwitchClient } from './selectors';
import { LoadingSpinner } from '../../../components/loading-spinner/loading-spinner.component';
import ModalConfirm from '../../../components_new/ModalConfirm';
import useUserCheck from './hooks/useUserCheck';
import Onboarding from './Onboarding';
import useOnboarding from './hooks/useOnboarding';

const NotificationWrapper = styled.div`
  .notification-container {
    top: 4rem;
    width: 350px;
  }

  .notification {
    opacity: 0.8;
    border-radius: 6px;
    padding: 20px 30px;
    &::before {
      right: 5px;
      top: 10px;
      left: auto;
      content: 'x';
      margin-top: 0;
      font-family: 'nunitosans';
      font-size: 20px;
    }
  }

  .notification-success {
    background-color: ${filtersBlue};
    opacity: 1;
  }

  .notification-error {
    background-color: ${notificationPink};
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  display: grid;

  grid-template-columns: min-content 1fr;
  grid-template-rows: 100%;
`;

const MainContentInnerContainer = styled.div`
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 2000px) {
    && {
      width: ${({ largeScreenWidth }) =>
        `${largeScreenWidth ? `${largeScreenWidth}px` : '1900px'}`};
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const MainView = (props) => {
  // Intercept error 401 response and logout user
  useEffect(() => {
    const unregister = fetchIntercept.register({
      response: function (response) {
        if (response.status === 401) {
          props.logout();
        }

        return response;
      },
    });

    return () => {
      unregister();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isLoadingSwitchClient = useSelector(getIsLoadingSwitchClient);
  const calendarAppContainerStyle = useCalendarAppContainer();

  //#region long polling user check
  //NOTE > we added it due the need to check if user switched organization in different tab or mobile device
  const {
    handleGetBackToCurrentClient,
    handleSwitchClient,
    isVisibleUserChangeInfoModal,
  } = useUserCheck();
  //#endregion

  const { shouldShowOnboarding } = useOnboarding();

  if (isLoadingSwitchClient || props.clientLoading) return <LoadingSpinner />;
  if (shouldShowOnboarding) return <Onboarding />;

  return (
    <div className="main-content" data-testid="mainView">
      <Header
        logout={props.logout}
        history={props.history}
        logo={props.logo}
        clientLoading={props.clientLoading}
      />
      <MainContentInnerContainer
        {...(calendarAppContainerStyle
          ? { style: calendarAppContainerStyle }
          : {})}
      >
        <Wrapper>
          <Sidebar />
          <ContentArea />
          <NotificationWrapper>
            <NotificationContainer />
          </NotificationWrapper>
        </Wrapper>
      </MainContentInnerContainer>
      {isVisibleUserChangeInfoModal ? (
        <ModalConfirm
          isOpen={isVisibleUserChangeInfoModal}
          handleClose={() => {}}
          handleAction={handleGetBackToCurrentClient}
          actionButtonColor="green"
          actionType="stay_here"
          titleKey="switched_organisation"
          intlValues={{
            messageKey: 'switched_organisation_unsaved_changes',
          }}
          hideCloseIcon
          secondaryActionType="switch"
          handleSecondaryAction={handleSwitchClient}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => {
  const client = getEntity(state, entities.CLIENT.reduxProp);
  const clientLoading = getEntityLoadingStatus(state, entities.CONFIG.name);
  return {
    logo: client?.settings?.logo_url,
    clientLoading,
  };
};
const mapActionsToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
});

export default connect(mapStateToProps, mapActionsToProps)(MainView);
