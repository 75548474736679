import { createSlice } from '@reduxjs/toolkit';
import { INIT_QUERY as INIT_QUERY_EXTERNAL_HOLDERS } from './ExternalHolders/constants';

const INITIAL_STATE = {
  groups: {
    ungroup: {
      isUngroupSuccessModalOpen: false,
      itemsCount: null,
    },
  },
  externalHolders: {
    query: INIT_QUERY_EXTERNAL_HOLDERS,
    filters: {},
    selectedItems: {},
  },
};

export const equipmentSlice = createSlice({
  name: 'equipment',
  initialState: INITIAL_STATE,
  reducers: {
    toggleUngroupSuccessModal: (state, action) => {
      state.groups.ungroup = {
        isUngroupSuccessModalOpen:
          !state.groups.ungroup.isUngroupSuccessModalOpen,
        itemsCount: action?.payload,
      };
    },
  },
});

export const { toggleUngroupSuccessModal } = equipmentSlice.actions;

export default equipmentSlice.reducer;
