import { useCallback, useMemo } from 'react';
import { ENTITIES, FILTERS_CONFIG, FILTER_COMPONENTS } from './constants';
import useFilters from './hooks/useFilters';
import useShowMore from './hooks/useShowMore';
import { ToggleFilters, ToggleFiltersWrapper, Wrapper } from './styles';
import { FormattedMessage } from 'react-intl';
import { setShowMore } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIsSaveModalOpen,
  getIsShowMoreVisible,
  getShowMore,
} from './selectors';
import { purple500 } from '../../config/colors';
import { ModalSave } from './components/Modals';
import { getUser } from '../../redux/user/selectors';

const ListFilters = (props) => {
  const {
    entity,
    blacklisted, //NOTE: this is due to the calendar entity given that 4 views use the same key but have to use different filters
  } = props;

  const dispatch = useDispatch();
  const showMore = useSelector(getShowMore);
  const loggedInUser = useSelector(getUser);
  const isShowMoreVisible = useSelector(getIsShowMoreVisible);

  const filters = useMemo(() => {
    if (entity === ENTITIES.issue) {
      const isMainOrg =
        loggedInUser?.client?.id === loggedInUser?.main_client_id;

      return isMainOrg
        ? FILTERS_CONFIG[entity]
        : FILTERS_CONFIG[entity].filter(
            (filter) => filter.id !== 6 && filter.id !== 13
          );
    }

    const hasBlacklisted = blacklisted && blacklisted.length > 0;

    return hasBlacklisted
      ? FILTERS_CONFIG[entity].filter(
          (filter) => !blacklisted.includes(filter.type)
        )
      : FILTERS_CONFIG[entity];
  }, [
    entity,
    loggedInUser?.client?.id,
    loggedInUser?.main_client_id,
    blacklisted,
  ]);

  const { wrapperRef, togglerRef, togglerWidth, togglerPositionRight } =
    useShowMore();

  useFilters({ entity });

  const renderFilter = useCallback((filter, index) => {
    const FilterComponent = FILTER_COMPONENTS[filter.type];
    return <FilterComponent key={filter.id} index={index} />;
  }, []);

  const toggleShowMore = () => dispatch(setShowMore(!showMore));
  const isSaveModalOpen = useSelector(getIsSaveModalOpen);

  return (
    <>
      <Wrapper ref={wrapperRef} pRight={!showMore ? togglerWidth + 15 : null}>
        {filters.map(renderFilter)}
        <ToggleFiltersWrapper
          onClick={toggleShowMore}
          color={showMore ? purple500 : null}
          isShowMore={showMore}
          ref={togglerRef}
          fromRight={togglerPositionRight}
          isDisabled={!isShowMoreVisible}
        >
          {showMore ? '- ' : null}
          <FormattedMessage id={showMore ? 'show_less' : 'show_more'} />
          {!showMore ? (
            <ToggleFilters
              className="icon icon-filters"
              spaceLeft="10"
              spaceRight="0"
              size="15"
            />
          ) : null}
        </ToggleFiltersWrapper>
      </Wrapper>
      {isSaveModalOpen ? <ModalSave isOpen={isSaveModalOpen} /> : null}
    </>
  );
};

export default ListFilters;
