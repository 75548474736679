import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dict from '../../../../config/multilang';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { mapUserForFilter } from '../../../../redux/entities/selectors';
import Tooltip from '../../../../components_new/Tooltip';
import { useState } from 'react';
import WorkspacesModal from '../../user/WorkspacesModal/WorkspacesModal';
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner.component';
import {
  ButtonWrapper,
  InfoContainer,
  TooltipWrapper,
  UserAssignmentTitle,
  UserAssignmentWrapper,
} from './styles';
import { InfoItemName, InfoSingleItemWrapper, InfoValue } from '../styles';
import { useAddUserToALlWorkspcacesMutation } from '../../users/service';
import useModalState from './../../Equipment/hooks/useModalState';
import UserAddedToAllWorkspacesModal from './../UserAddedToAllWorspacesModal/UserAddedToAllWorkspacesModal';
import ButtonAdd from '../../../../components_new/ButtonAdd';
import ActionsMenu from './../../../../components/sticky-header/ActionsMenu';
import ActionButton from '../../../../components/sticky-header/ActionButton';
import { updatePersistedFilters } from '../../../../redux/config/actions';
import { INIT_QUERY_PARAMS } from '../../IssuesV2/constants';
import { internalRoutePaths } from '../../../../config/config';
import Space from '../../../../components_new/Space';

const UserAssignment = ({ user, canManageUsers }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addUserToAllWorkspaces] = useAddUserToALlWorkspcacesMutation();
  const { isVisible, toggleModalState } = useModalState(false);
  const [success, setSuccess] = useState(null);
  const [showSpinnerOn, setShowSpinnerOn] = useState(null);

  const handleActiveIssuesClick = () => {
    if (!user.active_issues) return;

    const assigne = mapUserForFilter(user);

    dispatch(
      updatePersistedFilters(
        {
          ...INIT_QUERY_PARAMS,
          assignees_params: [{ id: assigne.id, full_name: assigne.value }],
        },
        'issue'
      )
    );

    history.push(internalRoutePaths.ISSUES);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUserAssign = (role, type) => async () => {
    setShowSpinnerOn(type);

    const response = await addUserToAllWorkspaces({
      id: user.id,
      params: { role, add_type: type },
    });

    if (response.data.success) setSuccess(true);
    else setSuccess(false);

    setShowSpinnerOn(null);
    toggleModalState();
  };

  return (
    <>
      <UserAssignmentWrapper>
        <UserAssignmentTitle>
          {intl.formatMessage({
            id: 'profile_assignment',
            defaultMessage: dict.profile_assignment,
          })}
        </UserAssignmentTitle>

        <InfoContainer>
          <ButtonWrapper>
            {canManageUsers ? (
              <>
                <ActionsMenu
                  customButton={(props) => (
                    <Button {...props} isLoading={showSpinnerOn === 'parent'} />
                  )}
                >
                  <ActionButton
                    variant="menu-item"
                    onClick={handleUserAssign('user', 'parent')}
                  >
                    <FormattedMessage id="as_user" />
                  </ActionButton>
                  <ActionButton
                    variant="menu-item"
                    onClick={handleUserAssign('manager', 'parent')}
                  >
                    <FormattedMessage id="as_manager" />
                  </ActionButton>
                </ActionsMenu>
                <ActionsMenu
                  customButton={(props) => (
                    <Button {...props} isLoading={showSpinnerOn === 'child'} />
                  )}
                >
                  <ActionButton
                    variant="menu-item"
                    onClick={handleUserAssign('user', 'child')}
                  >
                    <FormattedMessage id="as_user" />
                  </ActionButton>
                  <ActionButton
                    variant="menu-item"
                    onClick={handleUserAssign('manager', 'child')}
                  >
                    <FormattedMessage id="as_manager" />
                  </ActionButton>
                </ActionsMenu>
              </>
            ) : null}
          </ButtonWrapper>
          <InfoSingleItemWrapper>
            <InfoItemName>
              <FormattedMessage id="workspaces" defaultMessage="Workspaces" />
            </InfoItemName>
            <TooltipWrapper>
              <Tooltip
                tooltipContent={
                  <FormattedMessage
                    id="view_workspaces"
                    defaultMessage="View workspaces"
                  />
                }
              >
                <InfoValue onClick={openModal} isClickable pLeft="5">
                  {user.workspaces_involved}
                </InfoValue>
              </Tooltip>
            </TooltipWrapper>
          </InfoSingleItemWrapper>

          <InfoSingleItemWrapper>
            <InfoItemName>
              <Space height="5" />
              <FormattedMessage
                id="sub_workspaces"
                defaultMessage="Sub-workspaces"
              />
            </InfoItemName>
            <TooltipWrapper>
              <Tooltip
                tooltipContent={
                  <FormattedMessage
                    id="view_workspaces"
                    defaultMessage="View workspaces"
                  />
                }
              >
                <Space height="5" />
                <InfoValue onClick={openModal} isClickable pLeft="5">
                  {user.sub_workspaces_involved}
                </InfoValue>
              </Tooltip>
            </TooltipWrapper>
          </InfoSingleItemWrapper>

          <InfoSingleItemWrapper>
            <InfoItemName>
              <FormattedMessage
                id="active_issues_label"
                defaultMessage="Active Issues"
              />
            </InfoItemName>

            <TooltipWrapper>
              <Tooltip
                tooltipContent={
                  <FormattedMessage
                    id="view_issues"
                    defaultMessage="View issues"
                  />
                }
              >
                <InfoValue
                  onClick={handleActiveIssuesClick}
                  isClickable
                  pLeft="5"
                >
                  {user.active_issues}
                </InfoValue>
              </Tooltip>
            </TooltipWrapper>
          </InfoSingleItemWrapper>
        </InfoContainer>
      </UserAssignmentWrapper>

      {isModalOpen ? (
        <WorkspacesModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          user={user}
        />
      ) : null}
      {isVisible ? (
        <UserAddedToAllWorkspacesModal
          isOpen={isVisible}
          onClose={toggleModalState}
          success={success}
        />
      ) : null}
    </>
  );
};

export default UserAssignment;

const Button = ({ onClick, isLoading }) => {
  return (
    <ButtonAdd
      appearance="contained"
      disabled={isLoading}
      onClick={onClick}
      icon={isLoading ? null : 'icon-plus'}
      style={{ width: 'auto', height: 27, marginTop: '-2px' }}
    >
      {isLoading ? (
        <LoadingSpinner size="small" />
      ) : (
        <FormattedMessage id="add_to_all_ws" />
      )}
    </ButtonAdd>
  );
};
