import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '../../../../../../redux/app/actions';
import InfiniteScroll from '../../../../../../components_new/InfiniteScroll';
import { NoNotificationsText, NoMoreNotificationsText } from '../styles';
import { useToggleReadNotificationMutation } from '../../../service';
import NotificationItem from '../NotificationItem/NotificationItem';
import { groupNotifications } from '../../../../../../utils/notificationUtil';
import { useIntl, FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { redirectUser } from '../utils';
import useModalState from './../../../../Equipment/hooks/useModalState';
import Modal from './../../../../../../components_new/Modal/index';
import { getNotificationsUnreadCount } from '../../../selectors';
import { setNotificationsUnreadCount } from '../../../slice';
import useGoToEquipment from '../hooks/useGoToEquipment';

const NotificationTabContent = ({
  itemsList,
  hasMore,
  handleLoadMoreWithPaginateBy,
  isFetching,
  closeDropdown,
  activeTab,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const intl = useIntl();
  const wrapperRef = useRef(null);
  const notificationsUnreadCount = useSelector(getNotificationsUnreadCount);

  const { handleGoToEquipmentListing } = useGoToEquipment();

  useEffect(() => {
    wrapperRef.current && wrapperRef.current.scrollIntoView();
  }, [activeTab]);

  const {
    isVisible: isVisibleIssueMissingModal,
    toggleModalState: toggleIssueMissingModal,
  } = useModalState();

  const [toggleReadNotification] = useToggleReadNotificationMutation();

  const onClick = async (id, readAt) => {
    const isRead = Boolean(readAt);

    const response = await toggleReadNotification({
      id,
      isRead,
    });

    if (response?.data.success) {
      if (isRead)
        dispatch(setNotificationsUnreadCount(notificationsUnreadCount + 1));
      else dispatch(setNotificationsUnreadCount(notificationsUnreadCount - 1));

      return response.data.success;
    }
  };

  const handleSetForceSurveyShowModal = (value) => {
    dispatch(appActions.setForceSurveyShowModal(value));
  };

  const handleNotificationSelect = async (notification) => {
    const isRead = Boolean(notification.read_at);

    if (!isRead) {
      await onClick(notification.id, notification.read_at);
    }
    const { data } = notification;

    if (!data) return;

    redirectUser(
      history,
      data,
      closeDropdown,
      handleGoToEquipmentListing,
      toggleIssueMissingModal,
      handleSetForceSurveyShowModal
    );
  };

  const items = groupNotifications(itemsList, intl);

  return (
    <>
      <InfiniteScroll
        height={700}
        dataLength={itemsList.length}
        handleLoadMore={handleLoadMoreWithPaginateBy}
        hasMore={hasMore}
        maxHeight={700}
      >
        <div ref={wrapperRef}>
          {items.map((item, index) => {
            if (!item.data.length) return null;

            return (
              <NotificationItem
                key={index}
                data={item.data}
                index={index}
                title={item.title}
                onNotificationSelect={handleNotificationSelect}
                toggleReadNotification={onClick}
              />
            );
          })}
          {!itemsList.length && !isFetching ? (
            <NoNotificationsText>
              <FormattedMessage id="no_notifications" />
            </NoNotificationsText>
          ) : null}

          {itemsList.length && !hasMore ? (
            <NoMoreNotificationsText>
              <i className="icon icon-wow" />
              <FormattedMessage
                id="no_more_notifications"
                defaultMessage="Hooray! No more notifications."
              />
            </NoMoreNotificationsText>
          ) : null}
        </div>
      </InfiniteScroll>
      {isVisibleIssueMissingModal ? (
        <Modal
          title={<FormattedMessage id="issue_missing_error" />}
          isOpen={isVisibleIssueMissingModal}
          onClose={toggleIssueMissingModal}
        >
          <FormattedMessage id="issue_not_existing" />
        </Modal>
      ) : null}
    </>
  );
};

export default NotificationTabContent;
