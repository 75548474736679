export const PERSISTED_FILTERS_KEY = 'asset-group';
export const INIT_QUERY_PARAMS = {
  page: '1',
  paginate_by: '20',
  sort_by: 'name',
  sort_direction: 'asc',
};

export const SUPPORTED_PARAMS = {
  search: {
    defaultValue: '',
    allowedValues: null,
  },
  page: {
    defaultValue: '1',
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  sort_by: {
    defaultValue: 'updated',
    allowedValues: ['created', 'updated', 'name'],
  },
  sort_direction: {
    defaultValue: 'asc',
    allowedValues: ['asc', 'desc'],
  },
  status: {
    defaultValue: null,
    allowedValues: null,
  },
  manager_ids: {
    defaultValue: null,
    allowedValues: null,
  },
  workspaces: {
    defaultValue: null,
    allowedValues: null,
  },
};

export const FEATURE_KEY = 'groups';

export const STATUSES = [
  { value: 'all', label: 'status_all' },
  { value: 'active', label: 'active' },
  { value: 'archived', label: 'archived' },
];
