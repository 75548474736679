import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { LoadingSpinner } from '../../../../components/loading-spinner/loading-spinner.component';
import dict from '../../../../config/multilang';
import { useSelector } from 'react-redux';
import {
  LogoImage,
  LogoLoader,
  RightSide,
  LoginMessage,
  CalendarButton,
  HeaderInnerContainer,
} from './headerStyles';
import './header.component.scss';
import { Btn } from '../../../../components/btn/btn.component';
import { WowIcon } from '../../../../components_new/WowBasicElements';
import { purple500 } from '../../../../config/colors';
import { internalRoutePaths } from '../../../../config/config';
import SupportActions from './components/SupportActions';
import useCalendarAppContainer from '../hooks/useCalendarAppContainer';
import { getCalendarPemission } from '../../../../redux/user/selectors';
import ProfileMenu from './components/ProfileMenu';

export default function Header(props) {
  const { clientLoading, logo, history, logout, isPublic, loginMessage } =
    props;

  const t = useIntl();

  const navigateToLogin = () => {
    history.push('/login');
  };

  const isCalendarActive = useSelector(getCalendarPemission);
  const calendarAppContainerStyle = useCalendarAppContainer();

  const handleGoToCalendar = () => {
    const isOnCalendarPage = history?.location?.pathname.includes('calendar');
    if (!isOnCalendarPage) history.push(internalRoutePaths.CALENDAR);
  };

  return (
    <div className="header" data-testid="mainHeader">
      <HeaderInnerContainer
        {...(calendarAppContainerStyle
          ? { style: calendarAppContainerStyle }
          : {})}
      >
        <div className="header__logo">
          {clientLoading ? (
            <LogoLoader>
              <LoadingSpinner small />
            </LogoLoader>
          ) : (
            <LogoImage src={logo} alt="Aplication logo" />
          )}
        </div>
        {isPublic ? (
          <>
            {!loginMessage ? null : (
              <RightSide>
                <LoginMessage>{loginMessage}</LoginMessage>
                <Btn
                  name={t.formatMessage({
                    id: 'log_in',
                    defaultMessage: dict.log_in,
                  })}
                  className="primary"
                  wide={true}
                  active="green"
                  onClick={navigateToLogin}
                  noTheme
                  green
                  hover
                  hoverFullButton
                  hoverDarken
                />
              </RightSide>
            )}
          </>
        ) : (
          <div className="header__menu">
            {isCalendarActive ? (
              <CalendarButton
                className="mainHeaderCalendarButton"
                onClick={handleGoToCalendar}
              >
                <WowIcon
                  className="icon icon-calendar"
                  size="20"
                  color={purple500}
                  spaceRight="7"
                />
                <FormattedMessage id="calendar" />
              </CalendarButton>
            ) : null}

            <SupportActions />

            <ProfileMenu logout={logout} />
          </div>
        )}
      </HeaderInnerContainer>
    </div>
  );
}
