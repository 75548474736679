import { FormattedMessage } from 'react-intl';

import NotificationBell from '../../NotificationBell/NotificationBell';

import {
  Container,
  ArrowDown,
  CurrentOrganization,
  AvatarWrapper,
  Menu,
  ProfileInfoContainer,
  UserInformation,
  ViewProfileButton,
  OrganisationsContainer,
  LoaderWrapper,
  MenuToggler,
} from './styles';
import OrganizationItem from './components/OrganizationItem';
import Avatar from '../../../../../../components_new/Avatar/Avatar';
import { LoadingSpinner } from '../../../../../../components/loading-spinner/loading-spinner.component';
import useProfileMenu from './hooks/useProfileMenu';
import { ID_KEYS } from '../../../constants';

import ActionButtons from './components/ActionButtons';

const ProfileMenu = ({ logout = () => null }) => {
  const {
    containerRef,
    handleMenuOpen,
    isLoading,
    user,
    currentOrganisation,
    isOpen,
    availableOrganisations,
    handleGoToProfile,
    handleSwitchClient,
    handleAction,
    isAdmin,
    collabFeatureActive,
  } = useProfileMenu();

  return (
    <>
      <NotificationBell />
      <Container ref={containerRef}>
        <MenuToggler onClick={handleMenuOpen} data-testid={ID_KEYS.menuToggler}>
          <ArrowDown />
          {currentOrganisation ? (
            <CurrentOrganization>
              {currentOrganisation.name}
            </CurrentOrganization>
          ) : null}
          <AvatarWrapper>
            <Avatar
              imageUrl={user?.profile_image}
              imageAlt={`${user?.full_name} profile picture`}
              data-testid={ID_KEYS.avatar}
            />
          </AvatarWrapper>
        </MenuToggler>
        {isOpen && (
          <Menu data-testid={ID_KEYS.menuWrapper}>
            {isLoading ? (
              <LoaderWrapper>
                <LoadingSpinner size="small" />
              </LoaderWrapper>
            ) : (
              <>
                <ProfileInfoContainer>
                  <AvatarWrapper $width="70px" $height="70px">
                    <Avatar
                      imageUrl={user?.profile_image}
                      imageAlt={`${user?.full_name} profile picture`}
                      data-testid={ID_KEYS.avatar}
                    />
                  </AvatarWrapper>

                  <UserInformation className="full-name">
                    {user?.full_name}
                  </UserInformation>
                  <UserInformation className="email">
                    {user?.email}
                  </UserInformation>
                  <ViewProfileButton type="button" onClick={handleGoToProfile}>
                    <FormattedMessage id="view" />{' '}
                    <FormattedMessage id="profile" />
                  </ViewProfileButton>
                  <OrganizationItem
                    isMain={currentOrganisation.is_main}
                    isCurrent
                    isLast
                    item={currentOrganisation}
                    onClick={() => {}}
                    collabFeatureActive={collabFeatureActive}
                  />
                </ProfileInfoContainer>

                <OrganisationsContainer $withoutPadding={!collabFeatureActive}>
                  {collabFeatureActive &&
                    availableOrganisations?.map((item, index) => (
                      <OrganizationItem
                        key={item.id}
                        isMain={item.is_main}
                        isLast={index === availableOrganisations?.length - 1}
                        item={item}
                        onClick={handleSwitchClient}
                        isClickable
                        collabFeatureActive={collabFeatureActive}
                      />
                    ))}

                  <ActionButtons
                    actionClick={handleAction}
                    logout={logout}
                    isAdmin={isAdmin}
                    marginTop={
                      availableOrganisations.length > 0 || !collabFeatureActive
                    }
                    collabFeatureActive={collabFeatureActive}
                  />
                </OrganisationsContainer>
              </>
            )}
          </Menu>
        )}
      </Container>
    </>
  );
};

export default ProfileMenu;
