import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../../redux/utils';
import qs from 'query-string';

// TODO: Use from constants instead of defining here
const baseUrl = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}`;

//REFACTOR, split to smaller pieces, move some stuff to utils/constants
export const equipmentApi = createApi({
  reducerPath: 'equipmentApi',
  baseQuery: fetchBaseQuery({
    baseUrl,

    prepareHeaders: (headers, { getState }) => {
      const token = getToken();

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      /* headers.set('Content-Type', `application/json`); */
      headers.set('Accept', `application/json`);
      return headers;
    },
  }),
  tagTypes: [
    'Equipment',
    'EquipmentGroup',
    'EquipmentType',
    'EquipmentHandout',
    'EquipmentActivity',
    'EquipmentQrCodeScanlog',
    'EquipmentGroupHandoutInfo',
    'Workspaces',
    'WorkspacesShort',
    'Users',
    'Reservations',
    'Requests',
    'EquipmentGroupReservations',
    'EquipmentExternalHolders',
  ],
  endpoints: (builder) => ({
    //#region users
    getUsers: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'users/', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'index' }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Users', id })),
              { type: 'Users', id: 'LIST' },
            ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    //#region Workspaces
    getWorkspaces: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'workspace/', query: { ...params, prune_offline: 1 } },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Workspaces', id })),
              { type: 'Workspaces', id: 'LIST' },
            ]
          : [{ type: 'Workspaces', id: 'LIST' }],
      //add provide tags
    }),
    getWorkspacesShort: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'workspace/short', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' }
        );

        return url;
      },
      providesTags: [{ type: 'WorkspacesShort', id: 'LIST' }],
    }),
    //#endregion workspaces

    //#region Equipment
    getEquipmentList: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'equipment/', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },
      transformResponse: (response) => {
        return {
          ...response,
          data: response.data.overdue
            ? [
                ...response.data.overdue,
                ...response.data.pending,
                ...response.data.regular,
              ]
            : response.data,
        };
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Equipment', id })),
              { type: 'Equipment', id: 'LIST' },
            ]
          : [{ type: 'Equipment', id: 'LIST' }],
    }),

    getEquipment: builder.query({
      query: (id) => {
        if (!id) return;

        const url = `equipment/${id}`;

        return url;
      },

      // TODO: This has not been tested yet. (Groups and types was)
      providesTags: (result) =>
        result
          ? [{ type: 'Equipment', id: result.id }]
          : [{ type: 'Equipment', id: 'LIST' }],
    }),

    createEquipment: builder.mutation({
      query(body) {
        return {
          url: `equipment`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [
        { type: 'Equipment', id: 'LIST' },
        { type: 'EquipmentActivity', id: 'LIST' },
      ],
    }),

    updateEquipment: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `equipment/${id}`,
          method: 'PUT',
          body,
        };
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under it's results.
      invalidatesTags: (result, error, { id }) => [
        { type: 'Equipment', id },
        { type: 'EquipmentActivity', id: 'LIST' },
      ],
    }),

    updateEquipmentAttachments: builder.mutation({
      query(data) {
        const { id, formData } = data;

        return {
          url: `equipment/${id}/upload`,
          method: 'POST',
          body: formData,
        };
      },

      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under it's results.
      invalidatesTags: (result, error, { id }) => [{ type: 'Equipment', id }],
    }),

    deleteEquipment: builder.mutation({
      query(id) {
        return {
          url: `equipment/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'Equipment', id: 'LIST' }],
      // invalidatesTags: (result, error, { id }) => [
      //   { type: 'Equipment', id },
      //   { type: 'EquipmentActivity', id: 'LIST' },
      // ],
    }),

    addMultipleEquipmentToGroup: builder.mutation({
      query(body) {
        return {
          url: `equipment/multiple/add_to_group`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Equipment', id: 'LIST' }],
    }),

    archiveMultipleEquipment: builder.mutation({
      query(body) {
        return {
          url: `equipment/multiple/archive`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Equipment', id: 'LIST' }],
    }),

    deleteMultipleEquipment: builder.mutation({
      query(body) {
        return {
          url: `equipment/multiple/delete`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Equipment', id: 'LIST' }],
    }),

    getEquipmentReservations: builder.query({
      query: (params) => {
        const { id, ...restOfParams } = params;

        const url = qs.stringifyUrl(
          { url: `equipment/${id}/reservation`, query: restOfParams },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },

      providesTags: (result) => {
        return result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Reservations', id })),
              { type: 'Reservations', id: 'LIST' },
            ]
          : [{ type: 'Reservations', id: 'LIST' }];
      },
    }),

    getEquipmentRequests: builder.query({
      query: ({ id }) => {
        return `equipment/${id}/pending_requests`;
      },
      providesTags: (result) => {
        return result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'Requests', id })),
              { type: 'Requests', id: 'LIST' },
            ]
          : [{ type: 'Requests', id: 'LIST' }];
      },
    }),

    createEquipmentReservation: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `equipment/${id}/reservation`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'Equipment', id },
          { type: 'Reservations', id: 'LIST' },
          { type: 'Equipment', id: 'LIST' },
        ];
      },
    }),

    createEquipmentReservationMultiple: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `equipment/multiple/multiple_reservation`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result) => {
        return result?.handled?.length
          ? [
              { type: 'Reservations', id: 'LIST' },
              { type: 'Equipment', id: 'LIST' },
            ]
          : [];
      },
    }),

    updateEquipmentReservation: builder.mutation({
      query(data) {
        const { id, reservationId, ...body } = data;
        return {
          url: `equipment/${id}/reservation/${reservationId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'Equipment', id },
          { type: 'Reservations', id: 'LIST' },
          { type: 'Equipment', id: 'LIST' },
        ];
      },
    }),

    deleteEquipmentReservation: builder.mutation({
      query(data) {
        const { id, reservationId } = data;
        return {
          url: `equipment/${id}/reservation/${reservationId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'Equipment', id },
          { type: 'Reservations', id: 'LIST' },
          { type: 'Equipment', id: 'LIST' },
        ];
      },
    }),

    acceptReservationInBuffer: builder.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `equipment/reservation/${id}/buffer_accept`,
          method: 'POST',
        };
      },
      invalidatesTags: (result, error, { equipmentId }) => {
        return [
          { type: 'Equipment', equipmentId },
          { type: 'Reservations', id: 'LIST' },
          { type: 'Equipment', id: 'LIST' },
          //{ type: 'Requests', id: 'LIST' },
        ];
      },
    }),

    declineReservationInBuffer: builder.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `equipment/reservation/${id}/buffer_decline`,
          method: 'POST',
        };
      },
      invalidatesTags: (result, error, { equipmentId }) => {
        return [
          { type: 'Equipment', equipmentId },
          { type: 'Reservations', id: 'LIST' },
          { type: 'Equipment', id: 'LIST' },
          //{ type: 'Requests', id: 'LIST' },
        ];
      },
    }),

    createEquipmentHandouts: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `equipment/${id}/handout`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'Equipment', id },
          { type: 'EquipmentHandout', id: 'LIST' },
          { type: 'EquipmentActivity', id: 'LIST' },
        ];
      },
    }),

    createEquipmentHandoutsMultiple: builder.mutation({
      query(data) {
        return {
          url: `equipment/multiple/mix_handout`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: (result) => {
        return result?.issued?.length
          ? [{ type: 'Equipment', id: 'LIST' }]
          : [];
      },
    }),

    cancelEquipmentReservationsMultiple: builder.mutation({
      query(payload) {
        return {
          url: `equipment/multiple/reservation/cancel-by-ids`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: () => {
        return [
          { type: 'Reservations', id: 'LIST' },
          { type: 'Equipment', id: 'LIST' },
        ];
      },
    }),

    updateEquipmentHandout: builder.mutation({
      query(data) {
        const { id, handoutId, ...body } = data;
        return {
          url: `equipment/${id}/handout/${handoutId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'Equipment', id },
          { type: 'EquipmentHandout', id: 'LIST' },
          { type: 'EquipmentActivity', id: 'LIST' },
        ];
      },
    }),

    deleteEquipmentHandout: builder.mutation({
      query(data) {
        const { id, handoutId } = data;
        return {
          url: `equipment/${id}/handout/${handoutId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'Equipment', id },
          { type: 'EquipmentHandout', id: 'LIST' },
          { type: 'EquipmentActivity', id: 'LIST' },
        ];
      },
    }),

    getEquipmentHandouts: builder.query({
      query: (params) => {
        const { density, isAdvancedFilterOpen, id, ...restOfParams } = params;

        const url = qs.stringifyUrl(
          { url: `equipment/${id}/handout`, query: restOfParams },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },

      providesTags: (result) => {
        return result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'EquipmentHandout',
                id,
              })),
              { type: 'EquipmentHandout', id: 'LIST' },
            ]
          : [{ type: 'EquipmentHandout', id: 'LIST' }];
      },
    }),

    extendEquipmentHandouts: builder.mutation({
      query(data) {
        return {
          url: `equipment/handout/extend`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'Equipment', id },
          { type: 'EquipmentHandout', id: 'LIST' },
          { type: 'EquipmentActivity', id: 'LIST' },
        ];
      },
    }),

    pendingEquipmentHandout: builder.mutation({
      query(data) {
        const { equipmentId, handoutId, action } = data;

        return {
          url: `/equipment/${equipmentId}/handout/${handoutId}/${action}`,
          method: 'POST',
        };
      },

      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under it's results.
      invalidatesTags: (result, error, { id }) => [
        { type: 'Equipment', id },
        { type: 'Requests', id: 'LIST' },
      ],
    }),

    pendingEquipmentGroupHandout: builder.mutation({
      query(data) {
        const { groupId, action } = data;
        return {
          url: `/equipment/group/${groupId}/${action}`,
          method: 'POST',
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Requests', id: 'LIST' },
        { type: 'EquipmentGroup', id: 'LIST' },
      ],
    }),

    getEquipmentActivities: builder.query({
      query: (params) => {
        const { density, isAdvancedFilterOpen, id, ...restOfParams } = params;

        const url = qs.stringifyUrl(
          { url: `equipment/${id}/activity_log`, query: restOfParams },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },

      providesTags: (result) => {
        return result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'EquipmentActivity',
                id,
              })),
              { type: 'EquipmentActivity', id: 'LIST' },
            ]
          : [{ type: 'EquipmentActivity', id: 'LIST' }];
      },
      transformResponse: (response) => {
        // Backend doesn't return id in activity object 🤮
        // TODO: Refactor when new activites are introduced
        return {
          ...response,
          data: response.data.map((item) => ({ ...item, id: item.created_at })),
        };
      },
    }),

    getEquipmentQrCodeScanlog: builder.query({
      query: (params) => {
        const { id, ...restOfParams } = params;

        const url = qs.stringifyUrl(
          { url: `qr_code/scan_log/${id}`, query: restOfParams },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },
      providesTags: (result) => {
        return result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'EquipmentQrCodeScanlog',
                id,
              })),
              { type: 'EquipmentQrCodeScanlog', id: 'LIST' },
            ]
          : [{ type: 'EquipmentQrCodeScanlog', id: 'LIST' }];
      },
    }),
    getEquipmentAvailability: builder.query({
      query: (ids) => {
        const url = qs.stringifyUrl(
          { url: 'equipment/availability', query: { ids } },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' }
        );
        return url;
      },
    }),
    getEquipmentMultipleReturnAvailability: builder.query({
      query: (equipment_ids) => {
        const url = qs.stringifyUrl(
          {
            url: 'equipment/multiple/return_availability',
            query: { equipment_ids },
          },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'bracket' }
        );
        return url;
      },
    }),
    equipmentMultipleReturn: builder.mutation({
      query(body) {
        return {
          url: `/equipment/multiple/return`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Equipment', id: 'LIST' },
      ],
    }),
    //#endregion

    //#region Equipment Groups
    getEquipmentGroups: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'equipment/group', query: params },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },

      // providesTags is used to tag data in cache so that
      // rtk-query knows what to re-fetch when we invalidate tag
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'EquipmentGroup', id })),
              { type: 'EquipmentGroup', id: 'LIST' },
            ]
          : [{ type: 'EquipmentGroup', id: 'LIST' }],
    }),

    getEquipmentGroup: builder.query({
      query: (id) => {
        const url = `equipment/group/${id}`;

        return url;
      },

      providesTags: (result) =>
        result
          ? [{ type: 'EquipmentGroup', id: result.id }]
          : [{ type: 'EquipmentGroup', id: 'LIST' }],
    }),

    ungroupEquipmentFromGroup: builder.mutation({
      query(id) {
        return {
          url: `equipment/group/${id}/ungroup`,
          method: 'POST',
        };
      },
      invalidatesTags: [{ type: 'EquipmentGroup', id: 'LIST' }],
    }),

    createEquipmentGroup: builder.mutation({
      query(body) {
        return {
          url: `equipment/group`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'EquipmentGroup', id: 'LIST' }],
    }),

    updateEquipmentGroup: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `equipment/group/${id}`,
          method: 'PUT',
          body,
        };
      },
      // Invalidates all queries that subscribe to this Post `id` only.
      // In this case, `getPost` will be re-run. `getPosts` *might*  rerun, if this id was under it's results.
      invalidatesTags: (result, error, { id }) => [
        { type: 'EquipmentGroup', id },
      ],
    }),

    deleteEquipmentGroup: builder.mutation({
      query(id) {
        return {
          url: `equipment/group/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'EquipmentGroup', id: 'LIST' }],
    }),

    createEquipmentGroupHandouts: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `equipment/group/${id}/handout`,
          method: 'POST',
          body,
        };
      },

      invalidatesTags: (result, error, { id }) => [
        { type: 'EquipmentGroup', id },
        { type: 'EquipmentGroupHandoutInfo', id },
        { type: 'Equipment', id: 'LIST' },
        { type: 'EquipmentGroupReservations', id: 'LIST' },
      ],
    }),

    createEquipmentGroupHandoutMultiple: builder.mutation({
      query(payload) {
        return {
          url: `equipment/multiple/mix_handout`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result) => {
        return result?.issued?.length
          ? [{ type: 'EquipmentGroup', id: 'LIST' }]
          : [];
      },
    }),

    updateEquipmentGroupHandout: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `equipment/group/${id}/handout`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'EquipmentGroup', id },
          { type: 'EquipmentGroupHandoutInfo', id },
          { type: 'Equipment', id: 'LIST' },
        ];
      },
    }),

    returnEquipmentGroup: builder.mutation({
      query(id) {
        return {
          url: `equipment/group/${id}/return`,
          method: 'POST',
        };
      },
      invalidatesTags: [{ type: 'EquipmentGroup', id: 'LIST' }],
    }),

    returnEquipmentGroupMultiple: builder.mutation({
      query(payload) {
        return {
          url: `equipment/group/multiple/return`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: [{ type: 'EquipmentGroup', id: 'LIST' }],
    }),

    getEquipmentGroupAvailability: builder.query({
      query: (id) => {
        const url = `equipment/group/${id}/availability`;

        return url;
      },
    }),

    getEquipmentGroupReservationAvailability: builder.query({
      query: (id) => {
        const url = `equipment/group/${id}/reservation_availability`;

        return url;
      },
    }),

    getGroupReservations: builder.query({
      query: (params) => {
        const { id, ...restOfParams } = params;

        const url = qs.stringifyUrl(
          { url: `equipment/group/${id}/reservation`, query: restOfParams },
          { skipEmptyString: true, skipNull: true, arrayFormat: 'comma' }
        );

        return url;
      },

      providesTags: (result) => {
        return result?.data
          ? [
              ...result.data.map(({ id }) => ({
                type: 'EquipmentGroupReservations',
                id,
              })),
              { type: 'EquipmentGroupReservations', id: 'LIST' },
            ]
          : [{ type: 'EquipmentGroupReservations', id: 'LIST' }];
      },
    }),

    createGroupReservation: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `equipment/group/${id}/reservation`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'EquipmentGroupReservations', id: 'LIST' },
          { type: 'EquipmentGroup', id: 'LIST' },
        ];
      },
    }),

    createGroupReservationMultiple: builder.mutation({
      query(payload) {
        return {
          url: `equipment/group/multiple/reservation`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: (result) => {
        return result?.reserved?.length
          ? [
              { type: 'EquipmentGroupReservations', id: 'LIST' },
              { type: 'EquipmentGroup', id: 'LIST' },
            ]
          : [];
      },
    }),

    cancelGroupReservationMultiple: builder.mutation({
      query(payload) {
        return {
          url: `equipment/multiple/reservation/cancel-by-ids`,
          method: 'POST',
          body: payload,
        };
      },
      invalidatesTags: () => {
        return [
          { type: 'EquipmentGroupReservations', id: 'LIST' },
          { type: 'EquipmentGroup', id: 'LIST' },
        ];
      },
    }),

    updateGroupReservation: builder.mutation({
      query(data) {
        const { id, reservationId, ...body } = data;
        return {
          url: `equipment/group/${id}/reservation/${reservationId}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'EquipmentGroupReservations', id: 'LIST' },
          { type: 'EquipmentGroup', id: 'LIST' },
        ];
      },
    }),

    deleteGroupReservation: builder.mutation({
      query(data) {
        const { id, reservationId } = data;
        return {
          url: `equipment/group/${id}/reservation/${reservationId}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: (result, error, { id }) => {
        return [
          { type: 'EquipmentGroupReservations', id: 'LIST' },
          { type: 'EquipmentGroup', id: 'LIST' },
        ];
      },
    }),

    getEquipmentGroupHandoutInfo: builder.query({
      query: (id) => {
        const url = `equipment/group/${id}/extend_availability`;

        return url;
      },
      providesTags: (result) => {
        return result
          ? [{ type: 'EquipmentGroupHandoutInfo', id: result.group_id }]
          : [{ type: 'EquipmentGroupHandoutInfo', id: 'LIST' }];
      },
    }),
    //#endregion Equipment Groups

    //#region Equipment Types
    getEquipmentTypes: builder.query({
      query: (params) => {
        const url = qs.stringifyUrl(
          { url: 'equipment/type', query: params },
          { skipEmptyString: true, skipNull: true }
        );

        return url;
      },
      providesTags: (result) =>
        result?.data
          ? [
              ...result.data.map(({ id }) => ({ type: 'EquipmentType', id })),
              { type: 'EquipmentType', id: 'LIST' },
            ]
          : [{ type: 'EquipmentType', id: 'LIST' }],
    }),

    getEquipmentType: builder.query({
      query: (id) => {
        const url = `equipment/type/${id}`;

        return url;
      },

      providesTags: (result) =>
        result
          ? [{ type: 'EquipmentType', id: result.id }]
          : [{ type: 'EquipmentType', id: 'LIST' }],
    }),

    createEquipmentType: builder.mutation({
      query(body) {
        return {
          url: `equipment/type`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'EquipmentType', id: 'LIST' }],
    }),

    updateEquipmentType: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `equipment/type/${id}`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'EquipmentType', id },
      ],
    }),

    deleteEquipmentType: builder.mutation({
      query(id) {
        return {
          url: `equipment/type/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [{ type: 'EquipmentType', id: 'LIST' }],
    }),
    //#endregion Equipment Types
    duplicateEquipment: builder.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `equipment/duplicate/${id}`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'Equipment', id },
        { type: 'Equipment', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  //Users
  useGetUsersQuery,
  useLazyGetUsersQuery,

  //workspaces
  useGetWorkspacesQuery,
  useGetWorkspacesShortQuery,
  useLazyGetWorkspacesShortQuery,

  // Equipment
  useGetEquipmentListQuery,
  useLazyGetEquipmentListQuery,
  useGetEquipmentQuery,
  useCreateEquipmentMutation,
  useUpdateEquipmentMutation,
  useDeleteEquipmentMutation,
  useAddMultipleEquipmentToGroupMutation,
  useArchiveMultipleEquipmentMutation,
  useDeleteMultipleEquipmentMutation,
  useUpdateEquipmentAttachmentsMutation,
  useGetEquipmentAvailabilityQuery,
  useGetEquipmentRequestsQuery,
  useGetEquipmentMultipleReturnAvailabilityQuery,
  useEquipmentMultipleReturnMutation,
  useCreateEquipmentReservationMultipleMutation,
  useCancelEquipmentReservationsMultipleMutation,

  // Handout
  useGetEquipmentHandoutsQuery,
  useCreateEquipmentHandoutsMutation,
  useExtendEquipmentHandoutsMutation,
  usePendingEquipmentHandoutMutation,
  usePendingEquipmentGroupHandoutMutation,
  useUpdateEquipmentHandoutMutation,
  useReturnEquipmentGroupMutation,
  useDeleteEquipmentHandoutMutation,
  useCreateEquipmentHandoutsMultipleMutation,

  //reservations
  useGetEquipmentReservationsQuery,
  useCreateEquipmentReservationMutation,
  useUpdateEquipmentReservationMutation,
  useDeleteEquipmentReservationMutation,
  useAcceptReservationInBufferMutation,
  useDeclineReservationInBufferMutation,

  // Activities
  useGetEquipmentActivitiesQuery,

  // QrCodeScanlog
  useGetEquipmentQrCodeScanlogQuery,

  // Groups
  useGetEquipmentGroupsQuery,
  useLazyGetEquipmentGroupsQuery,
  useGetEquipmentGroupQuery,
  useCreateEquipmentGroupMutation,
  useUpdateEquipmentGroupMutation,
  useDeleteEquipmentGroupMutation,
  useUngroupEquipmentFromGroupMutation,
  useCreateEquipmentGroupHandoutsMutation,
  useGetEquipmentGroupReservationAvailabilityQuery,
  useGetEquipmentGroupHandoutInfoQuery,
  useUpdateEquipmentGroupHandoutMutation,
  useCreateEquipmentGroupHandoutMultipleMutation,

  // Group reservations
  useGetGroupReservationsQuery,
  useGetEquipmentGroupAvailabilityQuery,
  useCreateGroupReservationMutation,
  useUpdateGroupReservationMutation,
  useDeleteGroupReservationMutation,
  useCreateGroupReservationMultipleMutation,
  useReturnEquipmentGroupMultipleMutation,
  useCancelGroupReservationMultipleMutation,

  // Types
  useGetEquipmentTypesQuery,
  useLazyGetEquipmentTypesQuery,
  useCreateEquipmentTypeMutation,
  useUpdateEquipmentTypeMutation,
  useDeleteEquipmentTypeMutation,

  useDuplicateEquipmentMutation,
} = equipmentApi;
