export const ENTITIES = {
  issue: 'issue',
  schedule: 'schedule',
  'yearly-calendar': 'yearly-calendar',
  calendar: 'calendar',
  template: 'template',
  'checklist-template': 'checklist-template',
  collaboration: 'collaboration',
  asset: 'asset',
  'asset-group': 'asset-group',
  'asset-system': 'asset-system',
  equipment: 'equipment',
  'equipment-group': 'equipment-group',
  'equipment-type': 'equipment-type',
  'external-holder': 'external-holder',
};
