import { initState, INIT_FILTER_STATE } from './initState';
import { actionStrings } from './actions';
import { actionStrings as authActions } from './../auth/actions';
import {
  formatWithNoEmptyValues,
  mergeCustomUrlParamsIntoInitFilters,
  mergeFilterSources,
} from './utils';

export const reducer = (state = initState, action) => {
  switch (action.type) {
    case actionStrings.LOAD_COLOURS:
      return {
        ...state,
        theme: action.payload,
      };
    case actionStrings.LOAD_CONFIG_SUCCESS: {
      const {
        colors,
        media_max_size,
        include_equipment,
        include_schedules,
        include_assets,
        include_collab,
        is_equipment_return_strict,
        edit_workspace,
        available_pdf_name_params,
        only_admins_see_users,
      } = action.payload.client.settings;

      //NOTE: this is needed if url params are manually added, eg link from email and/or due to BE inconsistency
      const initFilterStateWithCustomParams =
        mergeCustomUrlParamsIntoInitFilters(
          INIT_FILTER_STATE,
          action.payload.urlConfig
        );

      return {
        ...state,
        theme: colors,
        flowConfig: action.payload.issue_flow,
        wsFormDropdownsDataSource: action.payload.menu,
        media_max_size,
        includeEquipment: include_equipment,
        includeSchedules: include_schedules,
        includeAssets: include_assets,
        includeCollab: include_collab,
        editWorkspace: edit_workspace,
        availablePdfNamesParams: available_pdf_name_params,
        isEquipmentReturnStrict: is_equipment_return_strict,
        filters: mergeFilterSources({
          urlParams: initFilterStateWithCustomParams,
          backendFilters: action.payload?.filters,
          defaultFilters: INIT_FILTER_STATE,
        }),
        sessionDurations: action.payload.session_durations,
        onlyAdminsCanSeeUsers: only_admins_see_users,
        client: action.payload.client,
        onboardingProgress: action.payload.onboarding_progress,
      };
    }

    case actionStrings.UPDATE_PERSISTED_FILTERS:
      return {
        ...state,
        filters: {
          ...state?.filters,
          [action.payload.targetKey]: {
            ...state?.filters?.[action.payload.targetKey],
            parameters: action.payload.data,
          },
        },
      };
    case actionStrings.ADD_TO_PERSISTED_FILTERS:
      return {
        ...state,
        filters: {
          ...state?.filters,
          [action.payload.targetKey]: {
            ...state?.filters?.[action.payload.targetKey],
            parameters: formatWithNoEmptyValues({
              ...state?.filters?.[action.payload.targetKey]?.parameters,
              ...action.payload.data,
            }),
          },
        },
      };
    case actionStrings.UPDATE_ONBOARDING_PROGRESS_SUCCESS:
      return {
        ...state,
        onboardingProgress: {
          ...state.onboardingProgress,
          ...action.payload,
        },
      };
    case authActions.LOGOUT:
      return {
        ...initState,
        theme: state.theme,
      };
    default:
      return state;
  }
};
