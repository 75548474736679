import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import {
  getMinimumParamsDifference,
  parseParameters,
  validateAllowedParams,
  validateValuesType,
} from './utils';

const useQueryParams = ({
  init, //initial values
  defaultInit, //minimum default params that should be used, eg, page, paginage_by etc, depending on API
  supported, //supported params
  blacklisted, //keys that should be excluded
  skip = false,
} = {}) => {
  const history = useHistory();
  const initParamsParsed = useMemo(
    () => parseParameters(init, blacklisted),
    [init, blacklisted]
  );

  const queryParams = useMemo(() => {
    //NOTE: fallback due to BE issues, eg somethimes returns empty []
    const diff = getMinimumParamsDifference(initParamsParsed, defaultInit);
    return validateAllowedParams({ ...diff, ...initParamsParsed }, supported);
  }, [defaultInit, initParamsParsed, supported]);

  useEffect(() => {
    if (!initParamsParsed || skip) return;

    const pathname = history?.location?.pathname;
    const diff = getMinimumParamsDifference(initParamsParsed, defaultInit);
    const validatedParams = validateAllowedParams(
      { ...diff, ...initParamsParsed },
      supported
    );

    const stringifiedParams = qs.stringify(validatedParams, {
      arrayFormat: 'comma',
    });

    history.replace(`${pathname}?${stringifiedParams}`);
  }, [defaultInit, history, initParamsParsed, skip, supported]);

  const updateQueryParams = useCallback(
    (query) => {
      if (query) validateValuesType(query);

      const onlyAllowedQueryParams = validateAllowedParams(query, supported);

      const stringified = qs.stringify(onlyAllowedQueryParams, {
        skipNull: true,
        skipEmptyString: true,
      });

      const pathname = history?.location?.pathname;

      history.replace(`${pathname}?${stringified}`);

      return onlyAllowedQueryParams;
    },

    [supported, history]
  );

  return {
    queryParams,
    updateQueryParams,
  };
};

export default useQueryParams;
