export const PERSISTED_FILTERS_KEY = 'equipment-type';

const INIT_QUERY = {
  page: '1',
  paginate_by: '20',
  sort_by: 'name',
  sort_direction: 'asc',
};

const SUPPORTED_PARAMS = {
  page: {
    defaultValue: 1,
    allowedValues: null,
  },
  paginate_by: {
    defaultValue: '20',
    allowedValues: ['20', '40', '60', '80', '160', '200'],
  },
  status: {
    defaultValue: null,
    allowedValues: null,
  },
  sort_by: {
    defaultValue: 'name',
    allowedValues: ['created', 'updated', 'name'],
  },
  sort_direction: {
    defaultValue: 'asc',
    allowedValues: ['asc', 'desc'],
  },
  search: {
    defaultValue: '',
    allowedValues: null,
  },
};

const FORM_DEFAULT_VALUES = {
  name: '',
  description: '',
  storage: 'WORKSPACE',
  workspace_id: null,
  handout_requires_approval: false,
  default_return_period_value: 1,
  default_return_period_type: 'WEEKS',
  max_reservation_duration_value: 1,
  max_reservation_duration_type: 'WEEKS',
  buffer_days_reservations_value: 1,
  buffer_days_reservations_type: 'BEFORE',
  set_max_reservation_duration: false,
  set_default_return_period: false,
  set_buffer_days_reservations: false,
};

const RETURN_PERIOD_OPTIONS = [
  {
    value: 'DAYS',
    key: 'days',
  },
  {
    value: 'WEEKS',
    key: 'weeks',
  },
  {
    value: 'MONTHS',
    key: 'months',
  },
];

export {
  SUPPORTED_PARAMS,
  INIT_QUERY,
  FORM_DEFAULT_VALUES,
  RETURN_PERIOD_OPTIONS,
};
