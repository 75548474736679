import { loadConfigSuccess } from './actions';
import { entities } from './../../config/entitiesConfig';
import { actions } from '../entities/actions';
import { jsonRequest } from '../service/action-creator';
import { REQUEST_METHODS } from '../../config/apiConfig';
import { NotificationManager } from 'react-notifications';
import { saveData, coloursStorageKey } from '../utils';
import { getUrlParams } from './utils';

const configURL =
  '/config?get_only=client,categories,templates,menu,issue_flow,filters,session_durations,onboarding_progress';

const saveColoursToLocalStorage = (config) => {
  try {
    saveData(config.client.settings.colors, coloursStorageKey);
  } catch {}
};

export const fetchConfig = () => async (dispatch) => {
  dispatch(actions.loadEntity(entities.CONFIG.name));

  const response = await dispatch(jsonRequest(REQUEST_METHODS.GET, configURL));
  if (!response || !response.client || !response.categories) {
    NotificationManager.error('error');

    return;
  }

  const urlParams = getUrlParams();

  dispatch(
    loadConfigSuccess(response, {
      pathname: window.location.pathname,
      urlParams,
    })
  );

  dispatch(actions.entityLoaded(entities.CONFIG.name));

  dispatch(actions.loadEntitySuccess(entities.CLIENT.name, response.client));

  saveColoursToLocalStorage(response);

  return response;
};
