import { ENTITIES } from './entities';

const FILTER_CONFIG_SCHEDULE = [
  { id: 1, type: 'statusSchedule' },
  { id: 2, type: 'workspaces' },
  { id: 3, type: 'users' },
  { id: 4, type: 'collaborators' },
  { id: 5, type: 'priority' },
  { id: 6, type: 'category' },
  { id: 7, type: 'assets' },
  { id: 8, type: 'position' },
  { id: 9, type: 'equipment' },
];

export const FILTERS_CONFIG = {
  [ENTITIES.issue]: [
    { id: 1, type: 'quick' },
    { id: 2, type: 'type' },
    { id: 3, type: 'status' },
    { id: 4, type: 'workspaces' },
    { id: 5, type: 'users' },
    { id: 6, type: 'collaborators' },
    { id: 7, type: 'date' },
    { id: 8, type: 'priority' },
    { id: 9, type: 'schedules' },
    { id: 10, type: 'category' },
    { id: 11, type: 'assets' },
    { id: 12, type: 'position' },
    { id: 13, type: 'externals' },
  ],
  [ENTITIES.schedule]: FILTER_CONFIG_SCHEDULE,
  [ENTITIES['yearly-calendar']]: FILTER_CONFIG_SCHEDULE,
  [ENTITIES.calendar]: [
    { id: 1, type: 'type' },
    { id: 2, type: 'status' },
    { id: 3, type: 'workspaces' },
    { id: 4, type: 'users' },
    { id: 5, type: 'priority' },
    { id: 6, type: 'schedules' },
    { id: 7, type: 'category' },
    { id: 8, type: 'assets' },
    { id: 9, type: 'position' },
    { id: 10, type: 'externals' },
  ],
  [ENTITIES.template]: [{ id: 1, type: 'category' }],
  [ENTITIES['checklist-template']]: [{ id: 1, type: 'category' }],
  [ENTITIES.collaboration]: [
    { id: 1, type: 'typeCollaborators' },
    { id: 2, type: 'statusCollaborators' },
    { id: 3, type: 'accessType' },
    { id: 4, type: 'workspaces' },
  ],
  [ENTITIES.asset]: [
    { id: 1, type: 'statusAssets' },
    { id: 2, type: 'workspaces' },
    { id: 3, type: 'assetsGroups' },
    { id: 4, type: 'assetsSystems' },
    { id: 5, type: 'assetsModels' },
    { id: 6, type: 'assetsManufacturers' },
    { id: 7, type: 'managers' },
    { id: 8, type: 'legalRequrements' },
  ],
  [ENTITIES['asset-group']]: [
    { id: 1, type: 'assetsCommonStatus' },
    { id: 2, type: 'workspaces' },
    { id: 3, type: 'managers' },
  ],
  [ENTITIES['asset-system']]: [{ id: 1, type: 'assetsCommonStatus' }],
  [ENTITIES.equipment]: [
    { id: 1, type: 'statusEquipment' },
    { id: 2, type: 'workspaces' },
    { id: 3, type: 'equipmentGroups' },
    { id: 4, type: 'equipmentTypes' },
    { id: 5, type: 'holder' },
    { id: 6, type: 'reservedFor' },
    { id: 7, type: 'managers' },
  ],
  [ENTITIES['equipment-group']]: [
    { id: 1, type: 'statusEquipment' },
    { id: 2, type: 'workspaces' },
    { id: 3, type: 'managers' },
    { id: 4, type: 'reservedFor' },
  ],
  [ENTITIES['equipment-type']]: [{ id: 1, type: 'statusEquipment' }],
  [ENTITIES['external-holder']]: [{ id: 1, type: 'statusEquipment' }],
};
