/* import { combineReducers } from 'redux'; */
import { reducer as appReducer } from './app/reducer';
import { reducer as authReducer } from './auth/reducer';
import { reducer as entitiesReducer } from './entities/reducer';
import { reducer as userReducer } from './user/reducer';
import { reducer as qrScanReducer } from './qrscan/reducer';
import { reducer as filterReducer } from './filter/reducer';
import { reducer as galleryReducer } from './gallery/reducer';
import { reducer as notificationsReducer } from './notifications/reducer';
import { reducer as configReducer } from './config/reducer';
import schedulingReducer from '../views/internal/scheduling/slice';
import contentAreaReducer from '../views/internal/main/content-area/slice';
import equipmentReducer from '../views/internal/Equipment/slice';
import workspacesReducer from '../views/internal/workspaces/slice';
import usersReducer from '../views/internal/users/slice';
import profileReducer from '../views/internal/user/slice';
import issuesReducer from '../views/internal/IssuesV2/slice';
import calendarReducer from '../views/internal/Calendar/slice';
import { equipmentApi } from '../views/internal/Equipment/service';
import { workspaceApi } from '../views/internal/workspaces/service';
import { assetsApi } from '../views/internal/Assets/service';
import { issuesApi as issuesApi2 } from '../views/internal/IssuesV2/service';
import { usersApi } from '../views/internal/users/service';
import { profileApi } from '../views/internal/main/service';
import { categoryApi } from '../views/internal/categories/service';
import { schedulesApi } from '../views/internal/scheduling/services';
import { clientApi } from '../views/internal/settings/service';
import { calendarApi } from '../views/internal/Calendar/service';
import { translatesApi } from '../components_new/TranslationButton/service';
import { publicPageConfiguratorApi } from '../views/internal/settings/PublicForms/service';
import { publicEquipmentApi } from '../views/external/equipment/ReportFound/service';
import { publicReportIssueWorkspaceApi } from '../views/external/workspace/ReportIssue/service';
import { dynamicFormsApi } from '../views/internal/templates/Forms/Preview/service';
import multiselectReducer from './multiselect/slice';
import checklistsReducer from './checklists/slice';
import { baseApi } from '../config/rtk';
import mainReducer from '../views/internal/main/slice';

import multiselectPreserveReducer from '../hooks/useMultiselect_new/slice';
import infiniteData from '../hooks/useInfinite/useInfiniteScrollData/slice';
import links from '../components_new/Links/slice';
import listFilters from '../components_new/ListFilters/slice';

export const reducers = {
  app: appReducer,
  auth: authReducer,
  entities: entitiesReducer,
  user: userReducer,
  issues: issuesReducer,
  calendar: calendarReducer,
  qrscan: qrScanReducer,
  filter: filterReducer,
  gallery: galleryReducer,
  notifications: notificationsReducer,
  config: configReducer,
  scheduling: schedulingReducer,
  contentArea: contentAreaReducer,
  equipment: equipmentReducer,
  workspaces: workspacesReducer,
  users: usersReducer,
  profile: profileReducer,
  multiselect: multiselectReducer,
  multiselectPreserve: multiselectPreserveReducer,
  checklists: checklistsReducer,
  main: mainReducer,
  infiniteData,
  links,
  listFilters,
  [baseApi.reducerPath]: baseApi.reducer, //NOTE: this should be the only API > inject the rest
  [equipmentApi.reducerPath]: equipmentApi.reducer,
  [workspaceApi.reducerPath]: workspaceApi.reducer,
  [assetsApi.reducerPath]: assetsApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [issuesApi2.reducerPath]: issuesApi2.reducer,
  [categoryApi.reducerPath]: categoryApi.reducer,
  [schedulesApi.reducerPath]: schedulesApi.reducer,
  [clientApi.reducerPath]: clientApi.reducer,
  [calendarApi.reducerPath]: calendarApi.reducer,
  [translatesApi.reducerPath]: translatesApi.reducer,
  [publicPageConfiguratorApi.reducerPath]: publicPageConfiguratorApi.reducer,
  [publicEquipmentApi.reducerPath]: publicEquipmentApi.reducer,
  [publicReportIssueWorkspaceApi.reducerPath]:
    publicReportIssueWorkspaceApi.reducer,
  [dynamicFormsApi.reducerPath]: dynamicFormsApi.reducer,
};

// export const createRootReducer = (history) =>
//   combineReducers({
//     // router: connectRouter(history),
//     app: appReducer,
//     auth: authReducer,
//     entities: entitiesReducer,
//     user: userReducer,
//     workspace: workspaceReducer,
//     issue: issueReducer,
//     qrscan: qrScanReducer,
//     filter: filterReducer,
//     gallery: galleryReducer,
//     notifications: notificationsReducer,
//     config: configReducer,
//     scheduling: schedulingReducer,
//     contentArea: contentAreaReducer,
//   });

export const rtkMiddlewares = [
  baseApi.middleware,
  equipmentApi.middleware,
  workspaceApi.middleware,
  clientApi.middleware,
  assetsApi.middleware,
  usersApi.middleware,
  profileApi.middleware,
  issuesApi2.middleware,
  categoryApi.middleware,
  schedulesApi.middleware,
  clientApi.middleware,
  calendarApi.middleware,
  translatesApi.middleware,
  publicPageConfiguratorApi.middleware,
  publicEquipmentApi.middleware,
  publicReportIssueWorkspaceApi.middleware,
  dynamicFormsApi.middleware,
];
