import { INIT_QUERY_PARAMS as INIT_ISSUE_LIST_QUERY_PARAMS } from '../../views/internal/IssuesV2/constants';
import { INIT_QUERY_PARAMS as INIT_CALENDAR_QUERY_PARAMS } from '../../views/internal/Calendar/constants';
import { INIT_QUERY_PARAMS as INIT_SCHEDULE_QUERY_PARAMS } from '../../views/internal/scheduling/constants';
import { INIT_QUERY_PARAMS_YEARLY } from '../../views/internal/Calendar/constants';
import { INIT_QUERY_PARAMS as INIT_WORKSPACE_QUERY_PARAMS } from '../../views/internal/workspaces/constants';
import { INIT_QUERY_PARAMS as INIT_QUERY_PARAMS_TEMPLATES } from '../../views/internal/templates/Autocomplete/constants';
import { INIT_QUERY_PARAMS as INIT_QUERY_PARAMS_CHECKLIST_TEMPLATES } from '../../views/internal/templates/Checklists/constants';
import { INIT_QUERY_PARAMS as INIT_QUERY_PARAMS_COLLABORATION } from '../../views/internal/Collaborators/constants';
import { INIT_QUERY_PARAMS as INIT_QUERY_PARAMS_ASSETS } from '../../views/internal/Assets/constants';
import { INIT_QUERY_PARAMS as INIT_QUERY_PARAMS_ASSET_GROUPS } from '../../views/internal/Assets/Groups/constants';
import { INIT_QUERY_PARAMS as INIT_QUERY_PARAMS_ASSET_SYSTEMS } from '../../views/internal/Assets/Systems/constants';
import { INIT_QUERY as INIT_QUERY_EQUIPMENT } from '../../views/internal/Equipment/constants';
import { INIT_QUERY as INIT_QUERY_EQUIPMENT_GROUPS } from '../../views/internal/Equipment/Groups/constants';
import { INIT_QUERY as INIT_QUERY_EQUIPMENT_TYPES } from '../../views/internal/Equipment/Types/constants';
import { INIT_QUERY as INIT_QUERY_EQUIPMENT_EXTERNAL_HOLDERS } from '../../views/internal/Equipment/ExternalHolders/constants';

export const defaultTheme = {
  main: ['#5B47FF', '#9243FD'],
  mainAccent: '#9243FD',
  secondary: '#25C465',
  secondaryAccent: '#5B47FF',
};
export const initState = {
  theme: defaultTheme,
  flowConfig: {},
  client: {},
  config: {},
};

export const INIT_FILTER_STATE = {
  issue: {
    query_string: '',
    parameters: INIT_ISSUE_LIST_QUERY_PARAMS ?? {},
  },
  calendar: {
    query_string: '',
    parameters: INIT_CALENDAR_QUERY_PARAMS ?? {},
  },
  schedule: {
    query_string: '',
    parameters: INIT_SCHEDULE_QUERY_PARAMS ?? {},
  },
  'yearly-calendar': {
    query_string: '',
    parameters: INIT_QUERY_PARAMS_YEARLY ?? {},
  },
  workspace: {
    query_string: '',
    parameters: INIT_WORKSPACE_QUERY_PARAMS ?? {},
  },
  template: {
    query_string: '',
    parameters: INIT_QUERY_PARAMS_TEMPLATES ?? {},
  },
  'checklist-template': {
    query_string: '',
    parameters: INIT_QUERY_PARAMS_CHECKLIST_TEMPLATES ?? {},
  },
  collaboration: {
    query_string: '',
    parameters: INIT_QUERY_PARAMS_COLLABORATION ?? {},
  },
  asset: {
    query_string: '',
    parameters: INIT_QUERY_PARAMS_ASSETS ?? {},
  },
  'asset-group': {
    query_string: '',
    parameters: INIT_QUERY_PARAMS_ASSET_GROUPS ?? {},
  },
  'asset-system': {
    query_string: '',
    parameters: INIT_QUERY_PARAMS_ASSET_SYSTEMS ?? {},
  },
  equipment: {
    query_string: '',
    parameters: INIT_QUERY_EQUIPMENT ?? {},
  },
  'equipment-group': {
    query_string: '',
    parameters: INIT_QUERY_EQUIPMENT_GROUPS ?? {},
  },
  'equipment-type': {
    query_string: '',
    parameters: INIT_QUERY_EQUIPMENT_TYPES ?? {},
  },
  'external-holder': {
    query_string: '',
    parameters: INIT_QUERY_EQUIPMENT_EXTERNAL_HOLDERS ?? {},
  },
};
