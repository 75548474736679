import { useHistory } from 'react-router-dom';
import { updatePersistedFilters } from '../../../../redux/config/actions';
import { INIT_QUERY_PARAMS, PERSISTED_FILTERS_KEY } from '../constants';
import { internalRoutePaths } from '../../../../config/config';
import { useDispatch } from 'react-redux';

const useGoToList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleGoToList = (query) => {
    if (!query) return;

    dispatch(
      updatePersistedFilters(
        {
          ...INIT_QUERY_PARAMS,
          ...query,
        },
        PERSISTED_FILTERS_KEY
      )
    );

    history.push(internalRoutePaths.ASSETS);
  };

  return {
    handleGoToList,
  };
};

export default useGoToList;
