import styled from 'styled-components/macro';
import {
  darkerGray,
  newBorder,
  newGrayVariant,
  newRed,
  purple500,
} from '../../config/colors';
import { WowIcon } from '../WowBasicElements';

export const Wrapper = styled.div`
  position: relative;
  z-index: 15;
  ${({ pRight }) => pRight && `padding-right: ${pRight}px;`};
`;

export const Label = styled.span`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: ${darkerGray};
  text-transform: capitalize;
`;

export const ResetButton = styled.button`
  color: ${({ isDisabled }) => (isDisabled ? newGrayVariant : newRed)};
  background: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  text-transform: capitalize;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

export const InnerWrapper = styled.div`
  padding-top: 10px;
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : '500px')};
`;

export const OptionWrapper = styled.div`
  padding: ${({ padding }) => (padding ? `${padding}px 0` : '10px 0')};
  border-bottom: ${({ noBorder }) =>
    noBorder ? 'none' : `1px solid ${newBorder}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  color: ${darkerGray};
  font-weight: 600;
  width: 100%;
  max-width: 100%;
  word-break: break-all;
  padding-right: 5px;
  ${({ isClickable }) => isClickable && 'cursor: pointer;'};
  ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'};
`;

export const OptionChildrenWrapper = styled.div`
  border-bottom: 1px solid ${newBorder};
`;

export const LoadingWrapper = styled.div`
  position: relative;
  height: 50px;
  margin-top: 20px;
`;

export const Counter = styled.div`
  border-radius: 20px;
  background-color: ${({ color }) => color ?? purple500};
  min-width: 22px;
  min-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  font-size: 13px;
  color: white;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 5px;
`;

export const ToggleFiltersWrapper = styled.div`
  display: ${({ isDisabled }) => (isDisabled ? 'none' : 'inline-block')};
  font-size: 15px;
  color: ${({ color }) => color ?? darkerGray};
  &:hover {
    cursor: pointer;
  }
  ${({ isShowMore, fromRight }) =>
    !isShowMore && `position: absolute; right: ${fromRight}px}; top: 7px;`};
`;

export const ToggleFilters = styled(WowIcon)`
  postition: absolute;
`;

export const Asterisk = styled.span`
  color: ${newRed};
  font-size: 14px;
  margin-left: 2px;
`;

export const ActionsLoadingWrapper = styled.div`
  position: relative;
  margin-left: 5px;
  width: 20px;
  height: 20px;

  > div {
    top: 50%;
  }
`;
