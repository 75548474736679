import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { FILTER_COMPONENTS } from '../constants';
import Space from '../../../../../../Space';
import { useDispatch, useSelector } from 'react-redux';
import { getPreviewFilter } from '../../../../../selectors';
import {
  FILTER_LABEL_KEYS,
  FILTER_LABEL_PREFIX_KEYS,
} from '../../../constants';
import { setIsPreviewModalOpen } from '../../../../../slice';

const usePreview = ({ isOpen } = {}) => {
  const dispatch = useDispatch();
  const previewFilter = useSelector(getPreviewFilter);

  const renderFilter = useCallback((filter, index) => {
    const { key, ...restProps } = filter;
    const FilterComponent = FILTER_COMPONENTS[key];
    return FilterComponent ? (
      <Fragment key={key}>
        {index > 0 && <Space height="20" />}
        <FilterComponent index={index} {...restProps} filterKey={key} />
      </Fragment>
    ) : null;
  }, []);

  const filters = useMemo(() => {
    const parameters = { ...(previewFilter?.params ?? {}) };
    const filters = [];

    for (const key of Object.keys(parameters)) {
      if (key in FILTER_COMPONENTS) {
        filters.push({
          key,
          value: parameters[key],
          labelKey: FILTER_LABEL_KEYS[key],
          labelPrefixKey: FILTER_LABEL_PREFIX_KEYS[key] ?? null,
        });
      } else if (key === 'date_events_from') {
        //NOTE - this is due to BE having different keys for date range
        const _filterKey = 'date';
        filters.push({
          key: _filterKey,
          value: {
            from: parameters[key],
            to: parameters['date_events_to'],
            types: parameters['date_events_types'],
          },
          filterKey: _filterKey,
          labelKey: FILTER_LABEL_KEYS[_filterKey],
        });
      }
    }

    return filters;
  }, [previewFilter?.params]);

  const handleClose = useCallback(
    () => dispatch(setIsPreviewModalOpen(false)),
    [dispatch]
  );

  useEffect(() => {
    if (!filters.length && isOpen) {
      handleClose();
    }
  }, [filters, handleClose, isOpen]);

  return {
    filters,
    renderFilter,
    handleClose,
  };
};

export default usePreview;
