import { internalRoutePaths } from '../../config/config';

export const ROUTE_MAPPED_PERSISTED_FILTER_KEY = {
  [internalRoutePaths.ISSUES]: 'issue',
  [internalRoutePaths.CALENDAR]: 'calendar',
  [internalRoutePaths.SCHEDULING]: 'schedule',
  [internalRoutePaths.WORKSPACES]: 'workspace',
  [internalRoutePaths.TEMPLATES_AUTOCOMPLETE]: 'template',
  [internalRoutePaths.TEMPLATES_CHECKLISTS]: 'checklist-template',
  [internalRoutePaths.COLLABORATORS]: 'collaboration',
  [internalRoutePaths.ASSETS]: 'asset',
  [internalRoutePaths.ASSETS_GROUPS]: 'asset-group',
  [internalRoutePaths.ASSETS_SYSTEMS]: 'asset-system',
  [internalRoutePaths.EQUIPMENT]: 'equipment',
  [internalRoutePaths.EQUIPMENT_GROUPS]: 'equipment-group',
  [internalRoutePaths.EQUIPMENT_TYPES]: 'equipment-type',
  [internalRoutePaths.EQUIPMENT_EXTERNAL_HOLDERS]: 'external-holder',
};
